import React from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import PerfectScrollbar from 'perfect-scrollbar';
import styled from "styled-components";
import { Search, ChevronDown, X, InfoIcon, Plus } from 'lucide-react';
import { setCoin, gameCoin, setCredit } from "../../../../actions/gameCoin";
import { __, wait, decode, encode, forceSatoshiFormat, Event, getUID, isMobile } from "../../../../Helper";
import storage from "../../../../Storage";
import coins from "../../../coins";
import socket from "../../../../Socket";
import C from "../../../../Constant";
import SkylaDepositModal from '../../Wallet/Deposit/ModalContent';
import SkylawWalletManage from './ManageCoins.js'

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${props => props.show ? 'flex' : 'none'};
  justify-content: center;
  align-items: center;
  z-index: 1050;
  padding: 20px;
   @media (max-width: 768px) {
    padding: 0px;
    // height:100vh;
  }
`;

const ModalContent = styled.div`
  background-color: #232626;
  border-radius: 12px;
  width: 100%;
  max-width: 570px;
  position: relative;
  animation: modalFadeIn 0.3s ease-out;
  min-height: 400px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;

  @keyframes modalFadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

   @media (max-width: 768px) {
    // padding: 0px;
    height:100vh;
      max-height: 100vh;
  }
`;

const ModalHeader = styled.div`
  display: grid;
  grid-template-columns: 24px 1fr 24px;  /* Create three columns: space for balance, title, close button */
  align-items: center;
  padding: 16px 24px;
  border-bottom: 1px solid #2A2D35;
  flex-shrink: 0;
  background-color: #323738 !important;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
`;

const ModalTitle = styled.h4`
  color: white;
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  grid-column: 2;  /* Place in middle column */
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: #6F767E;
  cursor: pointer;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column: 3;  /* Place in last column */
  margin-left: auto;  /* Push to the end */
  
  &:hover {
    color: white;
  }
`;
const ModalBody = styled.div`
  padding: 24px;
  color: white;
  overflow-y: auto;
  flex-grow: 1;
  
  /* Custom scrollbar styles */
  &::-webkit-scrollbar {
    width: 6px;
  }
  
  &::-webkit-scrollbar-track {
    background: #2A2D35;
    border-radius: 3px;
  }
  
  &::-webkit-scrollbar-thumb {
    background: #454951;
    border-radius: 3px;
  }
  
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
     @media (max-width: 768px) {
    padding: 12px 14px;
    height:100vh;
  }
`;

// const CloseButton = styled.button`
//   background: none;
//   border: none;
//   color: #6F767E;
//   cursor: pointer;
//   padding: 4px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
  
//   &:hover {
//     color: white;
//   }
// `;


const DepositInfo = styled.div`
  background-color: #2A2D35;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 20px;
`;

const QRContainer = styled.div`
  background-color: white;
  padding: 16px;
  border-radius: 8px;
  width: fit-content;
  margin: 20px auto;
`;

const AddressContainer = styled.div`
  background-color: #2A2D35;
  border-radius: 8px;
  padding: 12px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Address = styled.input`
  background: none;
  border: none;
  color: white;
  width: 100%;
  margin-right: 12px;
  outline: none;
  font-family: monospace;
`;

const CopyButton = styled.button`
  background: linear-gradient(90deg, rgb(36, 238, 137), rgb(159, 232, 113));
  color: black;
  border: none;
  border-radius: 6px;
  padding: 8px 16px;
  font-weight: 600;
  cursor: pointer;
  
  &:hover {
    opacity: 0.9;
  }
`;


const Container = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  max-width: 500px;
`;

const CoinSelector = styled.div`
  background-color: #1E2024;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  gap: 8px;
//   border:2px solid rgb(58 65 66);
  cursor: pointer;
  min-width: 120px;
  position: relative;

  &:hover {
    background-color: #2A2D35;
  }
`;

const CoinIcon = styled.img`
  width: 24px;
  height: 24px;
`;

const CoinText = styled.span`
  color: white;
  font-size: 14px;
  font-weight: 500;
`;

const CreditAmount = styled.span`
  color: white;
  font-size: 14px;
  margin-right: 8px;
`;

const DepositButton = styled.div`
  background: linear-gradient(90deg, rgb(36, 238, 137), rgb(159, 232, 113));
  color: black;
  border-radius: 0;
  font-weight: bold !important;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border: 2px solid rgb(58 65 66);
  padding: 8px 16px; // Default padding

  @media (max-width: 768px) {
    padding: 2px 16px;
  }

  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
  &:hover {
    background-color: #00A876;
  }
`;

const DropdownContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 8px;
  background-color: #1E2024;
  border-radius: 8px;
  width: 280px;
  max-height: 400px;
  overflow-y: auto;
  z-index: 1000;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  display: ${props => props.show ? 'block' : 'none'};
`;

const SearchContainer = styled.div`
  padding: 12px;
  border-bottom: 1px solid #2A2D35;
  display:flex;
  align-items:center;
  gap:5px;
`;

const SearchInput = styled.div`
  background-color: #2A2D35;
  border-radius: 8px;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  gap: 8px;

  input {
    background: none;
    border: none;
    color: white;
    width: 100%;
    outline: none;
    font-size: 14px;

    &::placeholder {
      color: #6F767E;
    }
  }
`;

const IconBg = styled.div`
  padding: 6px 8px;
  background-color: #2A2D35;
  border-radius:8px;
  cursor:pointer;
 
`;

const CoinList = styled.div`
  padding: 8px 0;
`;

const CoinItem = styled.div`
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: background-color 0.2s;
  
  &:hover {
    background-color: #2A2D35;
  }

  ${props => props.active && `
    background-color: #2A2D35;
  `}
`;

const CoinItemLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const DifferentAmount = styled.li`
  list-style: none;
  text-align: right;
  animation: fadeInDown 0.3s ease-in-out;
  margin: 4px 0;
  
  &.text-success {
    color: #00C087;
  }
  
  &.text-danger {
    color: #FF4136;
  }
  
  &.fadeOutDown {
    animation: fadeOutDown 0.3s ease-in-out;
  }
`;

class SkylaCredit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            token: storage.getKey('token'),
            coin: storage.getKey('coin') ? storage.getKey('coin') : "BTC",
            activeClientCoins: storage.getKey('active_coins'),
            selectedCoin: storage.getKey('coin'),
            credits: {},
            filteredCoins: [],
            uid: getUID,
            different: [],
            credit: false,
            usd: storage.getKey('usd') === "true" ? true : false,
            searchTerm: "",
            showDepositModal: false,
            showManage:false,
            depositAddress: '1A1zP1eP5QGefi2DMPTfTL5SLmv7DivfNa' // Example address
        };
        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    toggleDepositModal = () => {
        this.setState(prevState => ({
            showDepositModal: !prevState.showDepositModal
        }));
    }

    toggleShowManage = () => {
      this.setState(prevState => ({
          showManage: !prevState.showManage
      }));
  }




    copyToClipboard = () => {
        navigator.clipboard.writeText(this.state.depositAddress)
            .then(() => {
                // You could add a success notification here
                console.log('Address copied!');
            })
            .catch(err => {
                console.error('Failed to copy:', err);
            });
    }


    updateAllCoins(newCoin = null, newVal = null) {
        const activeClientCoins = storage.getKey('active_coins');
        let coinsToDisplay = [];

        if (activeClientCoins !== null) {
            const ac = activeClientCoins.split(",");
            
            coinsToDisplay = __.reverse(ac).map(num => {
                const item = __.find(coins, { 'id': parseFloat(num) });
                if (__.isUndefined(item)) return null;
                
                let value;
                if (newVal !== null && __.lowerCase(item.preffix) === newCoin) {
                    value = newVal;
                    this.state.credits[newCoin] = value;
                } else {
                    value = forceSatoshiFormat(this.state.credits[__.lowerCase(item.preffix)]);
                }

                return {
                    preffix: item.preffix,
                    image: item.image,
                    value: value,
                    isActive: __.lowerCase(item.preffix) === __.lowerCase(this.state.selectedCoin)
                };
            }).filter(item => item !== null);
        } else {
            coinsToDisplay = coins.map(item => ({
                preffix: item.preffix,
                image: item.image,
                value: forceSatoshiFormat(this.state.credits[__.lowerCase(item.preffix)] || 0),
                isActive: __.lowerCase(item.preffix) === __.lowerCase(this.state.selectedCoin)
            }));
        }

        this.setState({ filteredCoins: coinsToDisplay });
    }
    componentDidMount() {
        this.props.gameCoin();

        if (this.state.token !== null) {
            socket.emit(C.CREDIT, encode({ token: this.state.token, coin: this.state.coin }));
        }

        socket.on(C.CREDIT, data => this.getCredit(decode(data)));
        socket.on(C.UPDATE_CREDIT, data => this.updateCredit(decode(data)));

        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
        socket.off(C.CREDIT);
        socket.off(C.UPDATE_CREDIT);
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.setState({ show: false });
        }
    }

    

    getCredit(data) {
        let { credit } = data;
        this.setState({ credits: credit });

        let currentCoin = __.lowerCase(this.props.coin);
        let currentCredit = forceSatoshiFormat(credit[currentCoin]);
        this.setState({ credit: currentCredit });
        storage.setKey('credit', currentCredit);

        this.props.setCredit(currentCredit);
        this.updateAllCoins();
    }

    updateCredit(data) {
        let credit = data.value;
        let coin = data.coin;

        let different;
        let arr;

        if (__.toNumber(credit) > __.toNumber(this.state.credit)) {
            different = __.toNumber(credit) - __.toNumber(this.state.credit);
            arr = {
                amount: different,
                color: 'text-success'
            }
        } else {
            different = __.toNumber(this.state.credit) - __.toNumber(credit);
            arr = {
                amount: different,
                color: 'text-danger'
            }
        }

        let check = forceSatoshiFormat(different);

        if (check.toString() !== '0.00000000') {
            this.setState(state => ({ different: [arr, ...state.different] }));
        }

        credit = forceSatoshiFormat(credit);
        this.setState({ credit });
        storage.setKey('credit', credit);

        this.props.setCredit(credit);
    }

    selectCoin(name) {
        if (storage.getKey('coin') === name) return;
        
        storage.setKey('coin', name);
        let credit = this.state.credits[__.lowerCase(name)];
        
        this.setState({ 
            coin: name, 
            show: false, 
            credit: credit, 
            selectedCoin: name 
        });

        this.props.setCoin(name);
        this.props.setCredit(credit);

        wait(200).then(() => {
            Event.emit('coin_changed', name);
        });
    }

    handleSearch = (e) => {
        this.setState({ searchTerm: e.target.value });
    }

    toggleDropdown = () => {
        this.setState(prevState => ({
            show: !prevState.show
        }));
    }

    addDiff(data, i) {
        let id = 'id_' + Math.floor(Math.random() * 1000 + 1);

        wait(2000).then(() => {
            try {
                document.getElementById(id).classList.remove('frd');
                document.getElementById(id).classList.add('fadeOutDown');
            }
            catch (e) { }
            this.state.different.splice(i, 1);
        });

        return (
            <DifferentAmount key={i} id={id} className={`animated frd ${data.color}`}>
                {(data.color === 'text-danger' ? '-' : '+')}
                {forceSatoshiFormat(data.amount, this.state.coin)}
            </DifferentAmount>
        );
    }

    render() {
        const { credit, different, coin, show, searchTerm, credits, filteredCoins } = this.state;
        const formattedCredit = forceSatoshiFormat(credit, coin, this.state.usd);
        const diff = different.map((data, i) => this.addDiff(data, i));

        const displayedCoins = filteredCoins.filter(coinItem => 
            coinItem.preffix.toLowerCase().includes(searchTerm.toLowerCase())
        );

        return (
            <Container ref={this.wrapperRef}>
            <div style={{ position: 'absolute', top: '-20px', right: '0' }}>
                <ul>{diff}</ul>
            </div>
            
            <CoinSelector onClick={this.toggleDropdown}>
                <CoinIcon src={`/assets/images/${__.lowerCase(coin)}.png`} alt={coin} />
                <CreditAmount>{formattedCredit}</CreditAmount>
                <ChevronDown size={16} color="#6F767E" />
            </CoinSelector>

            <DepositButton onClick={this.toggleDepositModal}>{
              isMobile() ? <div style={{fontSize:'20px', fontWeight:'bold'}}>+</div>:'Deposit'
              }</DepositButton>

            <DropdownContainer show={show}>
                <SearchContainer>
                    <SearchInput>
                        <Search size={16} color="#6F767E" />
                        <input
                            type="text"
                            placeholder="Search coins..."
                            value={searchTerm}
                            onChange={this.handleSearch}
                        />
                    </SearchInput>
                    <IconBg  onClick={this.toggleShowManage}>
                      <Plus size={22}/>
                    </IconBg>

                </SearchContainer>

                <CoinList>
                    {displayedCoins.map((coinItem, index) => (
                        <CoinItem 
                            key={index}
                            onClick={() => this.selectCoin(coinItem.preffix)}
                            active={coinItem.isActive}
                        >
                            <CoinItemLeft>
                                <CoinIcon 
                                    src={`/assets/images/${coinItem.image}`} 
                                    alt={coinItem.preffix}
                                />
                              
                                <CoinText>{coinItem.preffix}</CoinText>
                                {coinItem.id === 70 && <div>hdh</div>}
                            </CoinItemLeft>
                            <CreditAmount>
                                {coinItem.value}
                            </CreditAmount>
                        </CoinItem>
                    ))}
                </CoinList>
               
            </DropdownContainer>
            <ModalOverlay show={this.state.showDepositModal}>
                    <ModalContent>
                        <ModalHeader>
                            <ModalTitle>Deposit </ModalTitle>
                            <CloseButton onClick={this.toggleDepositModal}>
                                <X size={24} />
                            </CloseButton>
                        </ModalHeader>
                        <ModalBody>
                         
                            <SkylaDepositModal/>
                        </ModalBody>
                    </ModalContent>
                </ModalOverlay>

                <SkylawWalletManage
  show={this.state.showManage} 
  onHide={this.toggleShowManage} 
/>
        </Container>
        );
    }
}

SkylaCredit.propTypes = {
    setCoin: PropTypes.func.isRequired,
    coin: PropTypes.string
};

const mapStateToProps = state => ({
    coin: state.items.coin
});

export default connect(mapStateToProps, { setCoin, gameCoin, setCredit })(SkylaCredit);