// import React, { Component } from 'react';
// import { Modal } from 'react-bootstrap';
// import styled from 'styled-components';
// import Cookies from 'js-cookie';
// import storage from '../../../Storage';
// import socket from '../../../Socket';
// import { Event, wait, decode, encode, sendNotfication } from '../../../Helper';
// import C from '../../../Constant';

// const StyledModal = styled(Modal)`
//   .modal-content {
//     background-color: #1e2124;
//     color: white;
//     border-radius: 18px;
//     overflow: hidden;
//   }
// `;

// const ModalHeader = styled.div`
//   background-color: #43B30B;
//   padding: 15px;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   border-bottom: 2px solid #2f7d08;
// `;

// const CloseButton = styled.button`
//   background: none;
//   border: none;
//   color: white;
//   font-size: 24px;
//   cursor: pointer;
//   display: flex;
//   justify-content: flex-end;
// `;

// const ModalBody = styled(Modal.Body)`
//   padding: 20px;
// `;

// const StyledForm = styled.form`
//   display: flex;
//   flex-direction: column;
//   gap: 15px;
// `;

// const StyledInput = styled.input`
//   background-color: #2b2f33;
//   border: 1px solid #3a3f44;
//   border-radius: 12px;
//   color: white;
//   padding: 14px;
//   &::placeholder {
//     color: #6c757d;
//   }
//   &:focus {
//     outline: none;
//     border-color: #43B30B;
//     box-shadow: 0 0 0 2px rgba(67, 179, 11, 0.2);
//   }
// `;

// const ButtonContainer = styled.div`
//   display: flex;
//   gap: 10px;
//   justify-content: center;
// `;

// const Button = styled.button`
//   padding: 20px 30px;
//   border-radius: 30px;
//   font-weight: bold;
//   cursor: pointer;
//   transition: all 0.3s ease;
// `;

// const RegisterButton = styled(Button)`
//   background-color: #8b5cf6;
//   color: white;
//   border: none;
//   width: 100%;
//   max-width: 200px;

//   @media (max-width: 768px) {
//     max-width: 150px;
//   }

//   @media (max-width: 480px) {
//     max-width: 100%;
//   }

//   &:hover {
//     background-color: #7c3aed;
//   }
// `;

// const Labeltext = styled.div`
//   color: #696F79;
//   font-size: 14px;
// `;

// const StyledSignUpButton = styled.div`
//   font-size: 16px;
//   color: #43B30B !important;
//   background-color: transparent;
//   border: none;
//   border-radius: 28px;
//   padding: 5px 10px;
//   font-weight: bold;
//   cursor: pointer;
//   white-space: nowrap;
// `;

// class Register extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             show: this.props.show ? this.props.show : false,
//             username: '',
//             email: '',
//             password: '',
//             confirmPassword: '',
//             referral: '',
//             status: false,
//             submitted: false,
//             disabled: false,
//             effect: 'zoomIn',
//             termsAccepted: false
//         };
//     }

//     componentDidMount() {
//         socket.on(C.REGISTER_USER, data => this.setRegister(decode(data)));
//         Event.on('register_form', () => {
//             this.handleShow();
//         });
//     }

//     setRegister = (data) => {
//         if (data.status === true) {
//             sendNotfication('Successfully Registered, Please Wait...', 'success', 'bottom-left');
//             Cookies.set("session", data.token, { expires: 14 });
//             storage.setKey('logged', true);
//             storage.setKey('token', data.token);
//             storage.setKey('name', data.name);
//             storage.setKey('avatar', data.avatar);
//             storage.setKey('email', data.email);
//             storage.setKey('credit', data.credit);
//             storage.setKey('room', data.room);
//             storage.setKey('friends', data.friends);

//             wait(500).then(() => {
//                 window.location.reload();
//             });
//         } else {
//             this.setState({ submitted: false, disabled: false });
//             sendNotfication(data.status, 'error', 'bottom-left');
//         }
//     };

//     handleShow = () => {
//         this.setState({ show: true, effect: 'zoomIn' });
//     }

//     handleClose = () => {
//         this.setState({ show: false, effect: 'zoomOut', disabled: false, status: false, submitted: false });
//     }

//     handleSubmit = async (e) => {
//         e.preventDefault();

//         this.setState({ submitted: true, disabled: true, status: 'Please wait' });

//         const { username, email, password, confirmPassword, referral, termsAccepted } = this.state;

//         if (!(username && email && password && confirmPassword && termsAccepted)) {
//             this.setState({ disabled: false, status: false });
//             sendNotfication('Please fill all required fields and accept the terms', 'error', 'bottom-left');
//             return;
//         }

//         if (password !== confirmPassword) {
//             sendNotfication('Passwords do not match', 'error', 'bottom-left');
//             this.setState({ disabled: false, status: false });
//             return;
//         }

//         wait(200).then(() => {
//             socket.emit(C.REGISTER_USER, encode({
//                 username: username,
//                 email: email,
//                 password: password,
//                 referral: referral,
//                 recaptcha: 'google'
//             }));
//         });
//     }

//     handleInputChange = (e) => {
//         this.setState({ [e.target.name]: e.target.value });
//     }

//     handleCheckboxChange = (e) => {
//         this.setState({ [e.target.name]: e.target.checked });
//     }

//     render() {
//         const { show, username, email, password, confirmPassword, referral, disabled, termsAccepted } = this.state;

//         return (
//             <>
//                 <StyledSignUpButton onClick={e => this.handleShow(e)}>
//                     Sign up
//                 </StyledSignUpButton>
//                 <StyledModal
//                     size="md"
//                     centered
//                     show={show}
//                     onHide={this.handleClose}
//                     aria-labelledby="register-modal"
//                     className={`animated ${this.state.effect}`}
//                 >
//                     <ModalHeader>
//                         <CloseButton onClick={this.handleClose}>×</CloseButton>
//                     </ModalHeader>
//                     <ModalBody>
//                         <div className="text-center mb-4">
//                             <h3>CREATE YOUR ACCOUNT</h3>
//                         </div>
//                         <StyledForm onSubmit={this.handleSubmit}>
//                             <Labeltext>Username</Labeltext>
//                             <StyledInput
//                                 name="username"
//                                 value={username}
//                                 onChange={this.handleInputChange}
//                                 placeholder="Username"
//                             />
//                             <Labeltext>Email Address</Labeltext>
//                             <StyledInput
//                                 type="email"
//                                 name="email"
//                                 value={email}
//                                 onChange={this.handleInputChange}
//                                 placeholder="Email Address"
//                             />
//                             <Labeltext>Password</Labeltext>
//                             <StyledInput
//                                 type="password"
//                                 name="password"
//                                 value={password}
//                                 onChange={this.handleInputChange}
//                                 placeholder="Password"
//                             />
//                             <Labeltext>Confirm Password</Labeltext>
//                             <StyledInput
//                                 type="password"
//                                 name="confirmPassword"
//                                 value={confirmPassword}
//                                 onChange={this.handleInputChange}
//                                 placeholder="Confirm Password"
//                             />
//                             <Labeltext>Referral Code (Optional)</Labeltext>
//                             <StyledInput
//                                 name="referral"
//                                 value={referral}
//                                 onChange={this.handleInputChange}
//                                 placeholder="Referral Code"
//                             />
//                             <div>
//                                 <input
//                                     type="checkbox"
//                                     name="termsAccepted"
//                                     checked={termsAccepted}
//                                     onChange={this.handleCheckboxChange}
//                                     id="termsCheckbox"
//                                 />
//                                 <label htmlFor="termsCheckbox" style={{ marginLeft: '10px', color: '#696F79' }}>
//                                     I agree to the Terms of Service and Privacy Policy
//                                 </label>
//                             </div>
//                             <ButtonContainer>
//                                 <RegisterButton type="submit" disabled={disabled}>
//                                     {disabled && <span className="spinner-grow spinner-grow-sm mr-1" role="status" aria-hidden="true"></span>}
//                                     Sign Up
//                                 </RegisterButton>
//                             </ButtonContainer>
//                         </StyledForm>
//                         <div className="text-center mt-3">
//                             <span>Already have an account? </span>
//                             <a href="#" onClick={() => { this.handleClose(); Event.emit('login_form'); }}>Sign in</a>
//                         </div>
//                     </ModalBody>
//                 </StyledModal>
//             </>
//         );
//     }
// }

// export default Register;





// import React, { Component } from 'react';
// import { Modal } from 'react-bootstrap';
// import styled from 'styled-components';
// import socket from "../../../Socket";
// import storage from "../../../Storage";
// import { decode, encode, getElement, sendNotfication, isEmail, Event, DEVELOPMENT, wait, randomString } from "../../../Helper";
// import C from "../../../Constant";
// import axios from 'axios';

// const StyledModal = styled(Modal)`
//   .modal-content {
//     background-color: #1e2124;
//     color: white;
//     border-radius: 18px;
//     overflow: hidden;
//   }
// `;

// const ModalHeader = styled.div`
//   background-color: #43B30B;
//   padding: 15px;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   border-bottom: 2px solid #2f7d08;
// `;

// const CloseButton = styled.button`
//   background: none;
//   border: none;
//   color: white;
//   font-size: 24px;
//   cursor: pointer;
//   display: flex;
//   justify-content: flex-end;
// `;

// const ModalBody = styled(Modal.Body)`
//   padding: 20px;
// `;

// const StyledForm = styled.form`
//   display: flex;
//   flex-direction: column;
//   gap: 15px;
// `;

// const StyledInput = styled.input`
//   background-color: #2b2f33;
//   border: 1px solid #3a3f44;
//   border-radius: 12px;
//   color: white;
//   padding: 14px;
//   &::placeholder {
//     color: #6c757d;
//   }
//   &:focus {
//     outline: none;
//     border-color: #43B30B;
//     box-shadow: 0 0 0 2px rgba(67, 179, 11, 0.2);
//   }
//     /* Hide number input spinners */
//   &::-webkit-outer-spin-button,
//   &::-webkit-inner-spin-button {
//     -webkit-appearance: none;
//     margin: 0;
//   }
//   /* Firefox */
//   &[type='number'] {
//     -moz-appearance: textfield;
//   }
// `;

// const Labeltext = styled.div`
//   color: #696F79;
//   font-size: 14px;
// `;

// const ButtonContainer = styled.div`
//   display: flex;
//   gap: 10px;
//   justify-content: center;
// `;
// const Button = styled.button`
//   padding: 20px 30px;
//   border-radius: 30px;
//   font-weight: bold;
//   cursor: pointer;
//   transition: all 0.3s ease;

//   /* Medium screens */
//   @media (max-width: 768px) {
//     padding: 15px 20px;
//     font-size: 14px;
//   }

//   /* Small screens */
//   @media (max-width: 480px) {
//     padding: 8px 12px;
//     font-size: 12px;
//     border-radius: 20px;
//   }
// `;

// const SignInButton = styled(Button)`
//   background-color: #8b5cf6;
//   color: white;
//   border: none;
//   width: 100%;
//   max-width: 200px;

//   /* Medium screens */
//   @media (max-width: 768px) {
//     max-width: 130px;
//   }

//   /* Small screens */
//   @media (max-width: 480px) {
//     max-width: 100px;
//     padding: 6px 10px;
//     font-size: 10px;
//   }

//   &:hover {
//     background-color: #7c3aed;
//   }
// `;

// // const SignUpButton = styled(Button)`
// //   background-color: #30353C;
// //   color: white;
// //   border: none;
// //   display: flex;
// //   align-items: center;
// //   justify-content: center;
// //   width: 100%;
// //   max-width: 120px;

// //   /* Medium screens */
// //   @media (max-width: 768px) {
// //     max-width: 100px;
// //   }

// //   /* Small screens */
// //   @media (max-width: 480px) {
// //     max-width: 80px;
// //     padding: 6px 10px;
// //     font-size: 10px;
// //   }

// //   &:hover {
// //     background-color: #374151;
// //   }
// // `;


// const SignUpButton = styled.div`
//   background: linear-gradient(to bottom, #4AE54A, #94E874);
//    padding: 7px 14px;
//   border-radius: 6px;
//   border: none;
//   font-weight: bold;
//   font-size: 14px;
//   color: black !important;
//   cursor: pointer;
//   transition: opacity 0.2s ease;
//   &:hover {
//     opacity: 0.9;
//   }
// `;

// class Register extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             isLogged: storage.getKey('logged'),
//             show: this.props.show ? this.props.show : false,
//             username: '',
//             email: '',
//             password: '',
//             phoneNumber:null,
//             referral: this.props.referral || '',
//             ruleChecked: false,
//             submitted: false,
//             disabled: false,
//             effect: 'zoomIn'
//         };
//     }

//     componentDidMount() {
//         socket.on(C.REGISTER_USER, data => this.setRegister(decode(data)));
//         Event.on('register_form', () => {
//             this.handleShow();
//         });
//     }

//     setRegister = (data) => {
//         if (data.error) {
//             this.setState({ disabled: false, submitted: false });
//             return sendNotfication(data.error, 'success', 'bottom-left');
//         }

//         if (data.status) {

//             console.log("datataaaa", data)
//             this.setState({ submitted: false });
//             wait(1000).then(() => {
//                 socket.emit(C.LOGIN_USER, encode({
//                     username: data.name,
//                     password: data.password,
//                     recaptcha: 'google'
//                 }));
//             });
//         }
//     };

//     handleShow = () => {
//         this.setState({ show: true, effect: 'zoomIn' });
//     }

//     handleClose = () => {
//         this.setState({ show: false, effect: 'zoomOut', disabled: false, submitted: false, ruleChecked: false });
//     }

//     verifyRefrelCode = async()=> {
//         try {

//             const response= await axios.get(`https://api.xoxowin.com/verify-referral-code/${this.state.referral}`)
//             console.log("response comes like", response)
    
//             return response.valid;
            
//         } catch (error) {
//             console.log("error", error)
//         }
//     }

//    handleSubmit =  async (e) => {
//     var verified
//         e.preventDefault();
//         this.setState({ submitted: true, disabled: true });

      

        
//         const { username, email, password, ruleChecked, referral,phoneNumber } = this.state;
//     if(referral && referral.trim()){
//         console.log("ghus ja")
//        verified= await this.verifyRefrelCode();
//         if(verified==false){
//             console.log("insdied false")
//             this.setState({ disabled: false });
//             sendNotfication('Please enter valid referrel Code', 'success', 'bottom-left');
//             return
//         }
//     }

//         if (!(username && password && email && ruleChecked)) {
//             this.setState({ disabled: false });
//             return;
//         }

//         if (username.length < 5) {
//             this.setState({ disabled: false });
//             sendNotfication('Please enter username more than 5 words', 'success', 'bottom-left');
//             return;
//         }

//         if (!isEmail(email)) {
//             this.setState({ disabled: false });
//             sendNotfication('Please enter valid email address', 'success', 'bottom-left');
//             return;
//         }
//       console.log(phoneNumber);
//         socket.emit(C.REGISTER_USER, encode({
//             username: username,
//             password: password,
//             email: email,
//             phone:phoneNumber,
//             method: true,
//             refree: referral||'',
//             aff: storage.getKey('aff') ? storage.getKey('aff') : null
//         }));
//         this.handleClose()
//     }

//     handleInputChange = (e) => {
//         this.setState({ [e.target.name]: e.target.value });
//     }

//     checkRule = () => {
//         this.setState(prevState => ({ ruleChecked: !prevState.ruleChecked }));
//     }

//     login = () => {
//         this.handleClose();
//         Event.emit('login_form');
//     }

//     render() {
//         const { show, username, email, password, ruleChecked, disabled, referral, phoneNumber } = this.state;
//         console.log("phone number", phoneNumber)

//         return (
//             <>
//                 <SignUpButton onClick={this.handleShow}>
//                     Sign up
//                 </SignUpButton>
//                 <StyledModal
//                     size="md"
//                     centered
//                     show={show}
//                     onHide={this.handleClose}
//                     aria-labelledby="register-modal"
//                     className={`animated ${this.state.effect}`}
//                 >
//                     <ModalHeader>
//                         <CloseButton onClick={this.handleClose}>×</CloseButton>
//                     </ModalHeader>
//                     <ModalBody>
//                         <div className="text-center mb-4">
//                             <h3>CREATE YOUR ACCOUNT</h3>
//                         </div>
//                         <StyledForm onSubmit={this.handleSubmit}>
//                             <Labeltext>Username</Labeltext>
//                             <StyledInput
//                                 type="text"
//                                 name="username"
//                                 value={username}
//                                 onChange={this.handleInputChange}
//                                 placeholder="Username"
//                             />
//                             <Labeltext>Email Address</Labeltext>
//                             <StyledInput
//                                 type="email"
//                                 name="email"
//                                 value={email}
//                                 onChange={this.handleInputChange}
//                                 placeholder="Email Address"
//                             />
//                             <Labeltext>Password</Labeltext>
//                             <StyledInput
//                                 type="password"
//                                 name="password"
//                                 value={password}
//                                 onChange={this.handleInputChange}
//                                 placeholder="Password"
//                             />
//                                <Labeltext>Phone Number</Labeltext>
//                             <StyledInput
//                                 type="number"
//                                 name="phoneNumber"
//                                 value={phoneNumber}
//                                 onChange={this.handleInputChange}
//                                 placeholder="Phone Number"
//                             />
//                             <Labeltext>Referral Code (Optional)</Labeltext>
//                             <StyledInput
//                                 name="referral"
//                                 value={referral}
//                                 onChange={this.handleInputChange}
//                                 placeholder="Referral Code"
//                             />

//                             <div>
//                                 <input
//                                     type="checkbox"
//                                     id="ruleCheck"
//                                     checked={ruleChecked}
//                                     onChange={this.checkRule}
//                                 />
//                                 <label htmlFor="ruleCheck" style={{ marginLeft: '10px', fontSize: '14px' }}>
//                                     I agree with the user agreement
//                                 </label>
//                             </div>
//                             <ButtonContainer>
//                                 <SignUpButton type="submit" disabled={disabled}>
//                                     {disabled && <span className="spinner-grow spinner-grow-sm mr-1" role="status" aria-hidden="true"></span>}
//                                     Sign Up
//                                 </SignUpButton>
//                                 <SignInButton onClick={this.login}>Sign In</SignInButton>
//                             </ButtonContainer>
//                         </StyledForm>
//                     </ModalBody>
//                 </StyledModal>
//             </>
//         );
//     }
// }

// export default Register;



import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import socket from "../../../Socket";
import storage from "../../../Storage";
import { decode, encode, isEmail, Event, wait, sendNotfication } from "../../../Helper";
import C from "../../../Constant";
import axios from 'axios';

const StyledModal = styled(Modal)`
  .modal-content {
    background-color: #232626;
    color: white;
    border-radius: 12px;
    overflow: hidden;
    max-width: 900px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    z-index: 999;
       height: 90vh;
    
    @media (min-width: 768px) {
      flex-direction: row;
    }
    
    @media (max-width: 768px) {
      padding: 16px;
      height: 100vh;
      border-radius: 0;
    }
  }
  
  .modal-dialog {
    max-width: 900px;
    margin: 0;
    height: 100vh;
    width: 100vw;
    z-index: 9999;
    
    @media (min-width: 768px) {
      margin: 1.75rem auto;
      height: auto;
      width: auto;
    }
  }
  
  &.modal {
    z-index: 1060;
  }
  
  @media (max-width: 768px) {
    margin: 0;
    padding: 0;
    
    &.modal-dialog-centered {
      min-height: 100vh;
      align-items: flex-start;
    }
  }
`;

const RightSection = styled.div`
  flex: 1;
  background-image: url('https://bc.game/modules/account2/assets/bg-b12a40dc.png');
  background-size: cover;
  background-position: center;
  position: relative;
  background-repeat:no-repeat;
  min-height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px;
  object-fit: cover;
  
  @media (min-width: 768px) {
    min-height: 450px;
    background-position: center top;
    overflow: hidden;
    background-size: 100% auto;
  }
  
  @media (max-width: 768px) {
    order: 1;
    padding: 12px;
    height: 90px;
    min-height: auto;
    background: url('https://bc.game/modules/account2/assets/bg_m-402471db.png') no-repeat;
    background-position: top;
    background-size: contain;
    justify-content: flex-end;
    
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 50%;
      background: linear-gradient(to bottom, rgba(35, 38, 38, 0), rgba(35, 38, 38, 1));
      pointer-events: none;
    }
  }
`;

const RightContent = styled.div`
  position: relative;
  z-index: 1;
  color: white;
`;

const LeftSection = styled(Modal.Body)`
  padding: 16px;
  flex: 1;
  position: relative;
  width: 100%;
  
  @media (max-width: 768px) {
    padding: 16px;
    order: 2;
    margin-top: -398px;
  }
`;

const StayUntamed = styled.div`
  h2 {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 16px;
    color: white;
    text-align: center;
    
    @media (max-width: 768px) {
      font-size: 20px;
      margin-bottom: 8px;
    }
  }

  p {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.8);
    text-align: center;
    
    @media (max-width: 768px) {
      font-size: 12px;
    }
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const CloseButton = styled.button`
  position: fixed;
  right: 16px;
  top: 16px;
  background: rgba(255, 255, 255, 0.1);
  border: none;
  color: white;
  font-size: 18px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
  
  @media (min-width: 768px) {
    position: absolute;
  }
  
  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
`;

const Title = styled.h2`
  font-size: 24px;
  color: white;
  margin-bottom: 16px;

  @media (max-width: 768px) {
    font-size: 18px;
    margin-bottom: 20px;
  }
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (max-width: 768px) {
    gap: 6px;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const StyledInput = styled.input`
  background-color: #111315;
  border: 1px solid #272B30;
  border-radius: 12px;
  color: white;
  padding: 8px 12px;
  width: 100%;
  font-size: 12px;

  @media (max-width: 768px) {
    padding: 10px 12px;
    font-size: 13px;
    border-radius: 8px;
  }

  &::placeholder {
    color: #6F767E;
  }

  &:focus {
    outline: none;
    border-color: #27ED87;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }
`;

const Label = styled.div`
  color: #6F767E;
  font-size: 14px;
  font-weight: 500;
  
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 6px 0;
`;

const CheckboxInput = styled.input`
  width: 20px;
  height: 20px;
  cursor: pointer;
  
  @media (max-width: 768px) {
    width: 16px;
    height: 16px;
  }
`;

const CheckboxLabel = styled.label`
  color: #6F767E;
  font-size: 12px;
  
  @media (max-width: 768px) {
    font-size: 11px;
  }
`;

const SignUpButton = styled.button`
  background: linear-gradient(to bottom, #4AE54A, #94E874);
  border: none;
  border-radius: 12px;
  color: black !important;
  padding: 12px 16px;
  font-weight: 600;
  font-size: 15px;
  width: 100%;
  cursor: pointer;
  margin-top: 12px;

  @media (max-width: 768px) {
    padding: 10px 12px;
    font-size: 14px;
    margin-top: 8px;
    border-radius: 8px;
  }

  &:hover {
    background-color: #00A06D;
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;

const SignUpButtonO = styled.div`
  font-size: 14px;
  background: linear-gradient(to bottom, #4AE54A, #94E874);
  border-radius: 10px;
  padding: 7px 14px;
  font-weight: bold;
  cursor: pointer;
  color: black !important;
  white-space: nowrap;

  @media (max-width: 768px) {
    width: 100%;
    text-align: center;
    font-size: 13px;
    padding: 6px 12px;
    border-radius: 8px;
  }
`;

const SignInText = styled.div`
  text-align: center;
  margin-top: 24px;
  color: #6F767E;
  font-size: 14px;

  @media (max-width: 768px) {
    margin-top: 16px;
    font-size: 12px;
  }
`;

const SignInLink = styled.span`
  color: #27ED87;
  cursor: pointer;
  margin-left: 8px;
  font-weight: 500;

  &:hover {
    text-decoration: underline;
  }
`;

const ModalClose = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: flex;
    height: 30px;
    width: 30px;
    justify-content: center;
    align-items: center;
    border-radius: 20%;
    background-color: #2D6A4D;
    position: absolute;
    padding: 12px;
    top: -550px;
    left: 299px;
    z-index: 9999;
  }
`;

class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLogged: storage.getKey('logged'),
            show: this.props.show ? this.props.show : false,
            username: '',
            email: '',
            password: '',
            phoneNumber: '',
            referral: this.props.referral || '',
            ruleChecked: false,
            marketingChecked: false,
            submitted: false,
            disabled: false,
            effect: 'zoomIn'
        };
    }

    componentDidMount() {
        socket.on(C.REGISTER_USER, data => this.setRegister(decode(data)));
        Event.on('register_form', () => {
            this.handleShow();
        });
    }

    setRegister = (data) => {
        if (data.error) {
            this.setState({ disabled: false, submitted: false });
            return sendNotfication(data.error, 'success', 'bottom-left');
        }

        if (data.status) {
            this.setState({ submitted: false });
            wait(1000).then(() => {
                socket.emit(C.LOGIN_USER, encode({
                    username: data.name,
                    password: data.password,
                    recaptcha: 'google'
                }));
            });
        }
    };

    handleShow = () => {
        this.setState({ show: true, effect: 'zoomIn' });
    }

    handleClose = () => {
        this.setState({
            show: false,
            effect: 'zoomOut',
            disabled: false,
            submitted: false,
            ruleChecked: false,
            marketingChecked: false
        });
    }

    verifyRefrelCode = async () => {
        try {
            const response = await axios.get(`https://api.xoxowin.com/verify-referral-code/${this.state.referral}`);
            return response.valid;
        } catch (error) {
            console.log("error", error);
            return false;
        }
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        this.setState({ submitted: true, disabled: true });

        const { username, email, password, ruleChecked, referral, phoneNumber } = this.state;

        if (referral && referral.trim()) {
            const verified = await this.verifyRefrelCode();
            if (!verified) {
                this.setState({ disabled: false });
                sendNotfication('Please enter valid referral code', 'success', 'bottom-left');
                return;
            }
        }

        if (!(username && password && email && ruleChecked)) {
            this.setState({ disabled: false });
            return;
        }

        if (username.length < 5) {
            this.setState({ disabled: false });
            sendNotfication('Please enter username more than 5 words', 'success', 'bottom-left');
            return;
        }

        if (!isEmail(email)) {
            this.setState({ disabled: false });
            sendNotfication('Please enter valid email address', 'success', 'bottom-left');
            return;
        }

        socket.emit(C.REGISTER_USER, encode({
            username: username,
            password: password,
            email: email,
            phone: phoneNumber,
            method: true,
            refree: referral || '',
            aff: storage.getKey('aff') ? storage.getKey('aff') : null
        }));
        this.handleClose();
    }

    handleInputChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    checkRule = () => {
        this.setState(prevState => ({ ruleChecked: !prevState.ruleChecked }));
    }

    checkMarketing = () => {
        this.setState(prevState => ({ marketingChecked: !prevState.marketingChecked }));
    }

    login = () => {
        this.handleClose();
        Event.emit('login_form');
    }

    render() {
      const {
          show,
          username,
          email,
          password,
          phoneNumber,
          referral,
          ruleChecked,
          marketingChecked,
          disabled
      } = this.state;

      return (
          <>
              <SignUpButtonO onClick={this.handleShow}>
                  Sign up
              </SignUpButtonO>
              <StyledModal
                  size="md"
                  centered
                  show={show}
                  onHide={this.handleClose}
                  aria-labelledby="register-modal"
                  className={`animated ${this.state.effect}`}
              >
                  <CloseButton onClick={this.handleClose}>×</CloseButton>
                  <RightSection>
                      <RightContent>
                          <ModalClose onClick={this.handleClose}>
                              x
                          </ModalClose>
                      </RightContent>
                      {/* <StayUntamed>
                          <h2>Stay Untamed</h2>
                          <p>Sign Up & Get Welcome Bonus</p>
                      </StayUntamed> */}
                  </RightSection>
                  <LeftSection>
                      <Title>Sign Up</Title>
                      <StyledForm onSubmit={this.handleSubmit}>
                          <InputWrapper>
                              <Label htmlFor="username">Username</Label>
                              <StyledInput
                                  type="text"
                                  id="username"
                                  name="username"
                                  placeholder="Enter username"
                                  value={username}
                                  onChange={this.handleInputChange}
                                  required
                              />
                          </InputWrapper>

                          <InputWrapper>
                              <Label htmlFor="email">Email</Label>
                              <StyledInput
                                  type="email"
                                  id="email"
                                  name="email"
                                  placeholder="Enter email"
                                  value={email}
                                  onChange={this.handleInputChange}
                                  required
                              />
                          </InputWrapper>

                          <InputWrapper>
                              <Label htmlFor="password">Password</Label>
                              <StyledInput
                                  type="password"
                                  id="password"
                                  name="password"
                                  placeholder="Enter password"
                                  value={password}
                                  onChange={this.handleInputChange}
                                  required
                              />
                          </InputWrapper>

                          <InputWrapper>
                              <Label htmlFor="phoneNumber">Phone Number (Optional)</Label>
                              <StyledInput
                                  type="tel"
                                  id="phoneNumber"
                                  name="phoneNumber"
                                  placeholder="Enter phone number"
                                  value={phoneNumber}
                                  onChange={this.handleInputChange}
                              />
                          </InputWrapper>

                          <InputWrapper>
                              <Label htmlFor="referral">Referral Code (Optional)</Label>
                              <StyledInput
                                  type="text"
                                  id="referral"
                                  name="referral"
                                  placeholder="Enter referral code"
                                  value={referral}
                                  onChange={this.handleInputChange}
                              />
                          </InputWrapper>

                          <CheckboxContainer>
                              <CheckboxInput
                                  type="checkbox"
                                  id="rules"
                                  checked={ruleChecked}
                                  onChange={this.checkRule}
                                  required
                              />
                              <CheckboxLabel htmlFor="rules">
                                  I agree to the Terms of Service and Privacy Policy
                              </CheckboxLabel>
                          </CheckboxContainer>

                          <CheckboxContainer>
                              <CheckboxInput
                                  type="checkbox"
                                  id="marketing"
                                  checked={marketingChecked}
                                  onChange={this.checkMarketing}
                              />
                              <CheckboxLabel htmlFor="marketing">
                                  I agree to receive marketing communications
                              </CheckboxLabel>
                          </CheckboxContainer>

                          <SignUpButton type="submit" disabled={disabled}>
                              Sign Up
                          </SignUpButton>
                      </StyledForm>

                      <SignInText>
                          Already have an account?
                          <SignInLink onClick={this.login}>Sign In</SignInLink>
                      </SignInText>
                  </LeftSection>
              </StyledModal>
          </>
      );
  }
}

export default Register;