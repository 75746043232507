import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { Info, Search, Eye, UserPlus, Menu, X, ChevronRight, Users, Settings } from 'lucide-react';
import Members from './Members';

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const slideIn = keyframes`
  from { transform: translateY(-20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(50, 55, 56, 0.8);
  backdrop-filter: blur(4px);
  z-index: 1000;
  padding: 1rem;
  animation: ${fadeIn} 0.3s ease-out;
           @media (max-width: 768px) {
  padding:0;
  }
`;

const ModalCard = styled.div`
  position: relative;
  width: 100%;
  max-width: 500px;
  height: 90vh;
  max-height: 800px;
  background: linear-gradient(135deg, #323738 0%, #4A5354 100%);
  border-radius: 24px;
  padding: 1.25rem;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3),
    0 0 0 1px rgba(82, 236, 130, 0.1);
  animation: ${slideIn} 0.3s ease-out;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  @media (min-width: 640px) {
    padding: 1.5rem;
    width:100%;
  }
  
  &::before {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    background: linear-gradient(45deg, #52EC82, #89E977);
    z-index: -1;
    border-radius: 24px;
    opacity: 0.2;
  }
   @media (max-width: 768px) {
   height:100vh;
    width: 100%;
      border-radius:0px;
  padding:1rem 0.55rem;
  }
`;

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-right: 0.5rem;
  
  &::-webkit-scrollbar {
    width: 6px;
  }
  
  &::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 3px;
  }
  
  &::-webkit-scrollbar-thumb {
    background: rgba(82, 236, 130, 0.3);
    border-radius: 3px;
  }
         @media (max-width: 640px) {
  padding:0;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: -0.2rem;
  right: 1rem;
  background: none;
  border: none;
  color: rgba(137, 233, 119, 0.6);
  cursor: pointer;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  z-index: 10;

  &:hover {
    color: #52EC82;
    transform: scale(1.1);
  }
`;

const ModalTitle = styled.div`
  font-size: 16px;
  font-weight: 800;
  text-align: center;
  margin: 0.5rem 0 1.5rem;
  background: linear-gradient(to right, #52EC82, #89E977);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  text-shadow: 0 2px 10px rgba(82, 236, 130, 0.3);

  @media (min-width: 640px) {
    font-size: 2rem;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-bottom: 1.5rem;
  position: relative;
`;

const ClubAvatar = styled.div`
  width: 70px;
  height: 70px;
  background: #4A5354;
  border-radius: 12px;
  position: relative;
  overflow: hidden;
  border: 2px solid rgba(82, 236, 130, 0.3);
  flex-shrink: 0;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .host-badge {
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(to right, #52EC82, #89E977);
    color: #323738;
    padding: 2px 6px;
    font-size: 0.7rem;
    font-weight: bold;
  }
`;

const ClubInfo = styled.div`
  flex: 1;
  min-width: 0;
  
  h1 {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 0.25rem;
    color: #52EC82;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .club-id {
    color: rgba(255, 255, 255, 0.6);
    font-size: 0.8rem;
  }
  
  .welcome-text {
    color: rgba(255, 255, 255, 0.6);

    font-size: 13.5px;
  }
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 0.75rem;
  
  button {
    background: #4A5354;
    border: none;
    color: #52EC82;
    cursor: pointer;
    padding: 0.4rem;
    border-radius: 50%;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    
    &:hover {
      background: rgba(82, 236, 130, 0.2);
      transform: translateY(-2px);
    }
  }
`;

const RakebackSection = styled.div`
  background: #4A5354;
  border-radius: 16px;
  padding: 1.25rem;
  margin-bottom: 1.5rem;
  border: 1px solid rgba(82, 236, 130, 0.2);
  backdrop-filter: blur(10px);

  .button-container {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    margin-top: 1.5rem;
    align-items:cneter;

    
    @media (min-width: 768px) {
      flex-direction: row;
      gap: 1rem;
    }
  }
`;

const RakebackHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.25rem;
  
  h2 {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1.1rem;
    color: rgba(255, 255, 255, 0.9);
  }
  
  .percentage {
    font-size: 1.75rem;
    font-weight: bold;
    color: #52EC82;
  }
`;

const ProgressContainer = styled.div`
  margin-top: 1.5rem;
`;

const PlayersProgress = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
  
  span {
    color: rgba(255, 255, 255, 0.6);
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }
`;

const PlayerMilestones = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  padding: 0 2px;
  margin-left: 10px;
  
  span {
    display: flex;
    align-items: center;
    color: rgba(255, 255, 255, 0.6);
    font-size: 0.8rem;
    
    svg {
      margin-left: 4px;
    }
  }
`;

const ProgressBar = styled.div`
  height: 6px;
  background: rgba(82, 236, 130, 0.1);
  border-radius: 3px;
  position: relative;
  margin: 0.75rem 0;
  
  .progress {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background: linear-gradient(to right, #52EC82, #89E977);
    border-radius: 3px;
    width: ${props => props.progress}%;
    transition: width 0.3s ease;
  }
`;

const ProgressLabels = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  
  span {
    font-size: 0.8rem;
    color: rgba(255, 255, 255, 0.6);
  }
`;

const ActionButton = styled.button`
  flex: 1;
  background: linear-gradient(to right, #52EC82, #89E977);
  color: black;
  border: none;
  border-radius: 20px;
  padding: 0.75rem;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  transition: all 0.3s ease;
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(82, 236, 130, 0.3);
  }
`;

const MenuToggle = styled.button`
  position: fixed;
  bottom: 1.5rem;
  right: 1.5rem;
  background: linear-gradient(to right, #52EC82, #89E977);
  border: none;
  border-radius: ${props => props.isOpen ? '20px' : '50%'};
  width: ${props => props.isOpen ? '140px' : '44px'};
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${props => props.isOpen ? '12px' : '0'};
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 100;
  
  svg {
    color: #323738;
  }
  
  &:hover {
    transform: ${props => props.isOpen ? 'none' : 'scale(1.1)'};
  }
`;

const Card = styled.div`
  background-color: #34393A;
  padding:0.5rem 1rem;
  border-radius: 0.5rem;
    border: 1px solid rgba(82, 236, 130, 0.2);
  width: 50%;
  display:flex;
  justify-content:center;
  align-items:center;
  flex-direction:column;
`;

const Title = styled.div`
  color: white;
  font-weight: 500;
  font-size:14px;
`;

const Value = styled.div`
  color: #56EC81;
  font-size:16px;
  font-weight: bold;
  margin-top: 2px;
`;
const Value2 = styled.span`
  color:#fff;
 
  font-weight: bold;
  margin-left:2px;
  margin-bottom:2px;
`;

const Detail = styled.p`
  color: #a1a1aa;
  margin-top: 0.25rem;
`;

const Container1 = styled.div`
  display: flex;
  gap:6px;
  margin:9px 0px;
`;




const CreateOwnClubModal = ({ isOpen, onClose }) => {
    const [players, setPlayers] = useState(2);
    const [currentRakeback, setCurrentRakeback] = useState(20);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isActive, setIsActive] = useState(false);

    const getProgressPercentage = (players) => {
        if (players <= 2) return 20;
        if (players <= 5) return 40;
        if (players <= 10) return 55;
        if (players <= 20) return 70;
        return 70;
    };

    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
            setIsMenuOpen(!isOpen)
        }
    };

    if (!isOpen) return null;

    return (

        <>
            {
                isOpen && (
                    <ModalOverlay onClick={handleOverlayClick}>
                        <ModalCard>
                            <CloseButton onClick={onClose}>
                                <X size={20} />
                            </CloseButton>

                            {/* <ModalTitle>Join a club</ModalTitle> */}
                            <Container>
                                <Header>
                                    <ClubAvatar>
                                        <div className="host-badge">Host</div>
                                        <img src="https://img.freepik.com/premium-vector/bearded-king-with-crown-his-head-logo-royal-king-symbol_939711-964.jpg" alt="Club avatar" />
                                    </ClubAvatar>
                                    <ClubInfo>
                                        <div style={{ color: 'white', fontSize: '18px', fontWeight: 'bold' }}>Club_hnitzxljou</div>
                                        <div className="club-id">Club ID: 393519</div>
                                        <div className="welcome-text">Welcome to the Club!</div>
                                    </ClubInfo>
                                    <ActionButtons>
                                        <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
                                            <div><Info color="#43D407" size={26} /></div>
                                            <img src={"/assets/images/swapskyla.png"} style={{ height: "26px", width: "26px", objectFit: "contain" }} alt="swap" />
                                        </div>
                                    </ActionButtons>
                                </Header>

                                <RakebackSection>
                                    <RakebackHeader>
                                        <h2>
                                            Club Rakeback
                                            <Eye size={18} />
                                        </h2>
                                        <div className="percentage">{currentRakeback}%</div>
                                    </RakebackHeader>

                                    <ProgressContainer>
                                        <PlayersProgress>
                                            <span>Players </span>
                                            <PlayerMilestones>
                                                {/* <span>0<Users size={12} /></span> */}
                                                <span style={{ style: 'fontSize:"10px' }}>5<Users style={{marginLeft:"-3px" ,color:"#52EC82"}} size={12} /></span>
                                                <span style={{ style: "fontSize:10px" ,marginLeft:"10px"}}>15<Users style={{marginLeft:"-3px" ,color:"#52EC82"}} size={12} /></span>
                                                <span style={{ style: "fontSize:10px", marginLeft:"10px" }}>50<Users style={{marginLeft:"-3px" ,color:"#52EC82"}} size={12} /></span>
                                                <span style={{ style: "fontSize:10px" , marginLeft:"10px"}}>100<Users style={{marginLeft:"-3px" ,color:"#52EC82"}} size={12} /></span>
                                                <span style={{ style: "fontSize:10px" }}>200<Users style={{marginLeft:"-3px" ,color:"#52EC82"}} size={12} /></span>
                                                <span style={{ style: "fontSize:10px" }}>300<Users style={{marginLeft:"-3px" ,color:"#52EC82"}} size={12} /></span>

                                            </PlayerMilestones>
                                        </PlayersProgress>

                                        <ProgressBar progress={getProgressPercentage(players)}>
                                            <div className="progress" />
                                        </ProgressBar>

                                        <ProgressLabels>
                                            <span style={{ style: "fontSize:10px" }}>0%</span>
                                            <span style={{ style: "fontSize:10px" }}>10%</span>
                                            <span style={{ style: "fontSize:10px" }}>15%</span>
                                            <span style={{ style: "fontSize:10px" }}>20%</span>
                                            <span style={{ style: "fontSize:10px" }}>25%</span>
                                            <span style={{ style: "fontSize:10px" }}>27%</span>
                                            <span style={{ style: "fontSize:10px" }}>30%</span>
                                        </ProgressLabels>
                                    </ProgressContainer>

                                    <Container1>
                                        <Card onClick={() => setIsActive(true)}>
                                            <Title>Active Players</Title>
                                            <Value>0/<Value2>1</Value2></Value>
                                            <Detail>Past 7 days</Detail>
                                        </Card>
                                        <Card>
                                            <Title>Total Rakeback</Title>
                                            <Value>$0</Value>
                                            <Detail>Yesterday: $0</Detail>
                                        </Card>
                                    </Container1>

                                    <div className="button-container">


                                        <ActionButton>
                                            Invite players
                                            <ChevronRight size={18} />
                                        </ActionButton>


                                    </div>
                                    <div style={{ textAlign: 'center', margin: '12px 0px' }}>Invite Players to earn more rakeback</div>


                                </RakebackSection>

                                <MenuToggle
                                    isOpen={isMenuOpen}
                                    onClick={() => setIsMenuOpen(!isMenuOpen)}
                                >
                                    {isMenuOpen ? (
                                        <>
                                            <Settings size={20} />
                                            <Users size={20} />
                                            <Settings size={20} />
                                        </>
                                    ) : (
                                        <Menu size={20} />
                                    )}
                                </MenuToggle>
                            </Container>
                        </ModalCard>
                    </ModalOverlay>
                )
            }

            {
                isActive && (
                    <ModalOverlay >
                        <ModalCard>
                            <CloseButton onClick={()=>setIsActive(false)}>
                                <X size={20} />
                            </CloseButton>

                            <ModalTitle>Member Details</ModalTitle>
                            <Members />
                        </ModalCard>
                    </ModalOverlay>
                )
            }
        </>
    );
};

export default CreateOwnClubModal;