import React from 'react';
import styled from 'styled-components';
import Social from '../../Pages/Parts/Sidebar/Social';
import { useSidebar } from '../../../context/SidebarContext';

const Container = styled.div`
  background-color: #1B1D21;
  padding: 20px;
`;

const FooterWrapper = styled.div`
  color: #ffffff;
   padding:20px 0px;
  //  max-width:80%;
  display: flex;
  justify-content: space-between;
  font-family: Arial, sans-serif;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 0.5px;
  background-color: #313536;  // Light gray color
  margin: 10px 0;
`;

const Section = styled.div`
  width: 50%;
  padding-left: 40px;
  justify-content: center;
  color: #8E969f;
  font-family: Arial, sans-serif !important;
  font-size: 14px;

  @media (max-width: 768px) {
    width: 100%;
    padding-left: 0;
    margin-bottom: 20px;
  }
`;

const Logo = styled.img`
  width: 150px;
  height: auto;
`;

const Description = styled.div`
  font-size: 14px;
  margin-top: 10px;
  color: #7F8893;
`;

const LinkHeader = styled.h3`
  color: #8E969f;
  font-family: Arial, sans-serif !important;
  font-size: 12px;
`;

const Link = styled.a`
  color: #8E969f;
  text-decoration: none;
  font-size: 12px;
  margin-bottom: 5px;
  display: block;
  &:hover {
    text-decoration: underline;
  }
`;

const CurrencyIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 5px;
`;

const Flex = styled.div`
  flex: 1;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const FlexWrap = styled.div`
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

// const Divider = styled.hr`
//   border: none;
//   border-top: 0.5px solid #8E969f;
//   margin: 16px 0;
//   width: 100%;
//   height: 0;
// `;

const BottomFlexWrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 20px 0px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }`;


  const FooterContainerTop = styled.div`
  padding: 40px 34px;
  margin-left: ${props => props.isCollapsed ? '160px' : '250px'};

  @media (max-width: 768px) {
    visibility: hidden;
    height: 0;
    overflow: hidden;
  }
`;

const AwardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
padding:20px 0px;
  background-color: #1E2121;
  overflow-x: auto;
  
`;

const AwardCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #2A2F30;
  border-radius: 8px;
  padding:4px 8px;
  min-width: 140px;
  transition: transform 0.2s;

  &:hover {
    transform: translateY(-5px);
  }
`;

const AwardImage = styled.div`
  width: 137px;
  height: 87px;


  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const AwardTitle = styled.h3`
  color: #c4a042;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  margin: 0;
  text-transform: uppercase;
`;

const AwardSubtitle = styled.p`
  color: #888;
  font-size: 12px;
  text-align: center;
  margin: 4px 0;
  text-transform: uppercase;
`;

const AwardYear = styled.span`
  color: #666;
  font-size: 12px;



`;


const LogoContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
  justify-content:center;
`;

const Logo1 = styled.div`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  display: flex;
  align-items: center;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    filter: brightness(0.8);
    transition: filter 0.3s ease;
    
    &:hover {
      filter: brightness(1);
    }
  }
`;





const awards = [
  {
    title: 'Best on Mobile',
    organization: 'Sigma Africa',
    year: '2024',
    image: 'https://bc.game/assets/mobile-sigma-africa-2024-DHtlyFtf.png'
  },
  {
    title: 'Best Casino Operator',
    organization: 'Sigma Europe',
    year: '2023',
    image: 'https://bc.game/assets/casino-sigma-europe-2023-kqUuEyFe.png'
  },
  {
    title: 'Crypto Game Platform',
    organization: 'Sigma Asia',
    year: '2023',
    image: 'https://bc.game/assets/platform-sigma-asia-2023-B6RzAtED.png'
  },
  {
    title: 'Crypto Casino',
    organization: 'Sigma Europe',
    year: '2022',
    image: 'https://bc.game/assets/casino-sigma-europe-2022-DQsRoTiA.png'
  },
  {
    title: 'Crypto Casino',
    organization: 'Sigma Africa',
    year: '2022',
    image: 'https://bc.game/assets/platform-sigma-america-2022-BOnPO8mV.png'
  },
  {
    title: 'Blockchain Gaming',
    organization: 'Platform America',
    year: '2022',
    image: 'https://bc.game/assets/platform-sigma-america-2022-BOnPO8mV.png'
  },
  {
    title: 'Crypto Casino',
    organization: 'Affpapa',
    year: '2022',
    image: 'https://bc.game/assets/crypto-casino-affpapa-2022-bYGRfjFE.png'
  },
  {
    title: 'Crypto Casino',
    organization: 'Sigma Europe',
    year: '2022',
    image: 'https://bc.game/assets/casino-aibc-europe-2022-DqIMtApB.png'
  }
];

const logos = [
  { id: 1, src: 'https://bc.game/assets/sigma-DzhjGPHa.png', alt: 'Sigma', width: 100, height: 40 },
  { id: 2, src: 'https://bc.game/assets/responsible-gambling--34MNE2A.png', alt: 'Responsible Gambling', width: 100, height: 60 },
  { id: 3, src: 'https://bc.game/assets/gamcare-ocLkkk6e.png', alt: 'GamCare', width: 100, height: 90 },
  { id: 4, src: 'https://bc.game/assets/betblocker-DBd5sP60.png', alt: 'Betblocker', width: 150, height: 50 },
  { id: 5, src: 'https://bc.game/assets/18-plus-DeAQLka0.png', alt: '18+', width: 50, height: 50 },
  { id: 6, src: 'https://bc.game/assets/c9-CnE9qdtZ.png', alt: 'Cloud9', width: 130, height: 45 },
  { id: 7, src: 'https://bc.game/assets/leicester-5bY-JKgX.png', alt: 'Circular Logo', width: 50, height: 50 },
  { id: 8, src: 'https://bc.game/assets/jason_derulo-fkEa4kAk.png', alt: 'Signature', width: 140, height: 40 }
];





const HelloFooter = () => {

  const { isCollapsed, setIsCollapsed } = useSidebar();
  return (
    //     <Container>
    //       <FooterWrapper>
    //         <Section>
    //           <Logo src="assets/images/lg.png" alt="FP.IO" />
    //           <Description>
    //             Skyla is built by gamblers for gamblers. With our unique community and a huge selection of games like Crash, HashDice, Plinko, Slots, and many more. Your greatest casino adventure is waiting for you!
    //           </Description>
    //         </Section>
    //         <Flex>
    //           <FlexWrap>
    //             <Section>
    //               <Link href="/provably-fair">HELP CENTER</Link>
    //               <Link href="/provably-fair">USER AGREEMENT</Link>
    //               <Link href="/provably-fair">PRIVACY POLICY</Link>
    //               {/* <Link href="#">APP</Link> */}
    //             </Section>
    //             <Section>
    //               <LinkHeader>CONTACT US</LinkHeader>
    //               <Link href="mailto:help@xoxowin.com">Email: <span style={{ color: '#3E9816' }}>support@xoxowin.coms</span></Link>
    //               <LinkHeader>ACCEPTED CURRENCIES</LinkHeader>
    //               <div style={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
    //                 <CurrencyIcon src="/assets/images/BTC.png" alt="Bitcoin" />
    //                 <CurrencyIcon src="/assets/images/eth.png" alt="Ethereum" />
    //                 <CurrencyIcon src="/assets/images/doge.png" alt="Dogecoin" />
    //                 <CurrencyIcon src="/assets/images/TETHER.png" alt="Tether" />
    //                 <CurrencyIcon src="/assets/images/ltc.png" alt="Litecoin" />
    //               </div>
    //             </Section>
    //           </FlexWrap>
    //         </Flex>
    //       </FooterWrapper>
    //       <Divider />
    //       <BottomFlexWrap>
    //         <div style={{ display: 'flex', gap: '4px', marginBottom: '10px' }}>
    //           <div style={{ textAlign: 'center', color: '#464B54' }}>
    //             <div>RESPONSIBLE</div>
    //             <div>GAMING</div>
    //           </div>
    //         </div>
    //  <Social/>

    //         <div style={{ color: '#464B54' }}>
    //           {/* <div>1BTC=$65750.81</div> */}
    //           <div>©2024 Skyla.io ALL RIGHTS RESERVED</div>
    //         </div>
    //       </BottomFlexWrap>
    //     </Container>

    <FooterContainerTop isCollapsed={isCollapsed}>

      <Divider />

      <AwardContainer >
        {awards.map((award, index) => (
          <AwardCard key={index}>
            <AwardImage>
              <img src={award.image} alt={`${award.title} award`} />
            </AwardImage>

          </AwardCard>
        ))}
      </AwardContainer>
      <Divider />
      <LogoContainer>
      <LogoWrapper>
        {logos.map(logo => (
          <Logo1 key={logo.id} width={logo.width} height={logo.height}>
            <img src={logo.src} alt={logo.alt} />
          </Logo1>
        ))}
      </LogoWrapper>
    </LogoContainer>
    <Divider />
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <FooterWrapper>
          <Section>
            <Logo src="assets/images/lg.png" alt="FP.IO" />
            <Description>
              xoxowin is built by gamblers for gamblers. With our unique community and a huge selection of games like Crash, HashDice, Plinko, Slots, and many more. Your greatest casino adventure is waiting for you!
            </Description>
          </Section>
          <Flex>
            <FlexWrap>
              <Section>
                <Link href="/provably-fair">HELP CENTER</Link>
                <Link href="/provably-fair">USER AGREEMENT</Link>
                <Link href="/provably-fair">PRIVACY POLICY</Link>
                {/* <Link href="#">APP</Link> */}
              </Section>
              <Section>
                <LinkHeader>CONTACT US</LinkHeader>
                <Link href="mailto:help@xoxowin.com">Email: <span style={{ color: '#3E9816' }}>help@xoxowin.com</span></Link>
                <LinkHeader>ACCEPTED CURRENCIES</LinkHeader>
                <div style={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
                  <CurrencyIcon src="/assets/images/BTC.png" alt="Bitcoin" />
                  <CurrencyIcon src="/assets/images/eth.png" alt="Ethereum" />
                  <CurrencyIcon src="/assets/images/doge.png" alt="Dogecoin" />
                  <CurrencyIcon src="/assets/images/TETHER.png" alt="Tether" />
                  <CurrencyIcon src="/assets/images/ltc.png" alt="Litecoin" />
                </div>
              </Section>
            </FlexWrap>
          </Flex>
        </FooterWrapper>
      </div>
    </FooterContainerTop>
  );
};

export default HelloFooter;