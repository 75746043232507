// import React, { Component, createRef } from 'react';
// import { withRouter } from "react-router-dom";
// import {Modal, Row, Col} from "react-bootstrap";
// import  Cookies from "js-cookie";
// // import ReCAPTCHA from "react-google-recaptcha";
// import storage from "../../../Storage";
// import GoogleBtn from "./Google";
// import Forget from "./Forget";
// import socket from "../../../Socket";
// import {Event, wait, decode, encode, sendNotfication, RECAPTCHA} from "../../../Helper";
// import C from "../../../Constant";

// class Login extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             isLogged: storage.getKey('logged'),
//             show: this.props.show ? this.props.show : false,
//             forgetPage: false,
//             username: '',
//             password: '',
//             // recaptcha: false,
//             status: false,
//             submitted: false,
//             disabled: false,
//             effect: 'zoomIn'
//         };
//         this.handleShow = this.handleShow.bind(this);
//         this.handleClose = this.handleClose.bind(this);
//         this.handleSubmit = this.handleSubmit.bind(this);

//         // this.recaptchaRef = createRef();
//     }

//     componentDidMount() {
//         socket.on(C.LOGIN_USER, data => this.setLogin(decode(data)));
//         Event.on('login_form', () => {
//             this.handleShow();
//         })
//     }

//     setLogin = (data) => {
//         if (data.status === true)
//         {
//             sendNotfication('Successfully Login, Please Wait...', 'success','bottom-left');
//             Cookies.set("session", data.token, {expires: 14});
//             storage.setKey('logged', true);
//             storage.setKey('token', data.token);
//             storage.setKey('name', data.name);
//             storage.setKey('avatar', data.avatar);
//             storage.setKey('email', data.email);
//             storage.setKey('credit', data.credit);
//             storage.setKey('room', data.room);
//             storage.setKey('friends', data.friends);

//             wait(500).then(() => {
//                 window.location.reload();
//             })

//         } else {
//             this.setState({submitted: false, disabled: false});
//             sendNotfication(data.status, 'success','bottom-left');
//         }
//     };

//     handleShow(e){
//         this.setState({ show: true, effect: 'zoomIn' });
//     }

//     handleClose(){
//         this.setState({ show: false, effect: 'zoomOut', disabled: false, status: false, submitted: false });
//     }

//     handleSubmit = async (e) => {
//         e.preventDefault();

//         this.setState({ submitted: true, disabled: true, status: this.props.t('please_wait') });

//         const { username, password } = this.state;

//         if (!(username && password)){
//             this.setState({ disabled: false, status: false });
//             return;
//         }

//         //Execute Recaptcha Token
//         // const token = await this.recaptchaRef.current.executeAsync();

//         wait(200).then(() => {
//             socket.emit(C.LOGIN_USER, encode({
//                 username: username,
//                 password: password,
//                 recaptcha: 'google'
//             }));
//         })
//     }

//     forgetPass = (e) => {
//         this.setState({ forgetPage: !this.state.forgetPage })
//     };

//     // recaptchaChange = (value) => {
//     //     this.setState({ recaptcha: value });
//     // };

//     register = (value) => {
//         this.handleClose();
//         Event.emit('register_form');
//     };

//     back = (value) => {
//         this.setState({ forgetPage: false })
//     };

//     render() {
//         let { t } = this.props; 
//         return (
//             <>
//                 <button className={'btn btn-transparent btn-round btn-sm no-shadow text-white mr-2 font-weight-bold'} onClick={e => this.handleShow(e)}>
//                     {t('login')}
//                 </button>
//                 <Modal
//                     size="md"
//                     centered={true}
//                     backdrop="static"
//                     show={this.state.show}
//                     onHide={this.handleClose}
//                     aria-labelledby="login-md-modal"
//                     className={'modalAuth animated ' + this.state.effect }
//                 >
//                     <Modal.Header>
//                          {this.state.forgetPage &&
//                             <button onClick={this.back} className="btn btn-transparent cpt hvw p-0">
//                                 <i className="fa fa-chevron-left fonts-18 aligdsn-top mr-3 mt-1" />
//                             </button>
//                          }
//                         <button type="button" className="close p-2" onClick={this.handleClose}>
//                             <i className={'mdi mdi-close'}/>
//                         </button>
//                     </Modal.Header>
//                     <Modal.Body className="auth-modal p-0">
//                         <div className="m-auto">
//                          {!this.state.forgetPage &&
//                             <div>
//                                 <div className="text-center">
//                                 {/*{this.state.recaptcha}*/}
//                                     <img src="/assets/images/lg.png" className={'img-fluid auth-logo'} alt="Logo"/>
//                                     <div className="text-center auth-logo-text">
//                                         <p className="mt-0 mb-3 mt-3 font-new text-white font-15">
//                                             {t('mega profit')}
//                                                 <i className="mdi mdi-dots-horizontal mx-2 font-18 align-middle" />
//                                             {t('fair games')}
//                                         </p>
//                                     </div>
//                                 </div>
//                                 <div className="px-4">
//                                     <form className="form-horizontal auth-form my-4" onSubmit={ (e) => { this.handleSubmit(e) }}>
//                                         <div className="form-group mb-2">
//                                             <div className="input-group">
//                                                 <div className="input-group-append">
//                                                     <span className="input-group-text bgp">{t('username')}</span>
//                                                 </div>
//                                                 <input type="text"
//                                                        className="form-control"
//                                                        value={this.state.username}
//                                                        autoComplete="off"
//                                                        onChange={e => this.setState({ username: e.target.value })}
//                                                        style={{ height: 40 }}

//                                                 />
//                                                 {this.state.submitted && !this.state.username &&
//                                                     <div className="help-block">{t('username_is_required')}</div>
//                                                 }
//                                             </div>
//                                         </div>
//                                         <div className="form-group mb-2">
//                                             <div className="input-group">
//                                                 <div className="input-group-append">
//                                                     <span className="input-group-text bgp">{t('password')}</span>
//                                                 </div>
//                                                 <input type="password"
//                                                        className="form-control"
//                                                        value={this.state.password}
//                                                        autoComplete="off"
//                                                        onChange={e => this.setState({ password: e.target.value })}
//                                                        style={{ height: 40 }}
//                                                 />
//                                                 {this.state.submitted && !this.state.password &&
//                                                     <div className="help-block">{t('password_is_required')}</div>
//                                                 }
//                                             </div>
//                                         </div>
//                                         <div className="text-center">
//                                             <button className="btn btn-auth btn-block font-weight-bold no-shadow" disabled={this.state.disabled}>
//                                                 {
//                                                     this.state.disabled &&
//                                                     <span className="spinner-grow spinner-grow-sm mr-1" role="loading"></span>
//                                                 }
//                                                 <i className="mdi mdi-login mr-1 float-left font-18" /> {t('login to site')}
//                                             </button>
//                                         </div>
//                                         <div className="mt-3">
//                                             <a href="#" className="" onClick={this.register}>
//                                                 Or Register a new account
//                                             </a>
//                                         </div>
//                                         {/*<ReCAPTCHA ref={this.recaptchaRef} size="invisible" sitekey={RECAPTCHA} />*/}
//                                     </form>
//                                     <Row className="text-center mb-4">
//                                         {/* <Col md="6" className="my-1">
//                                             <GoogleBtn />
//                                         </Col> */}
//                                         <Col md="6" className="my-1">
//                                             <button onClick={this.forgetPass} className="btn btn-block btn-outline-dark shadow-none">
//                                                 <i className="mdi mdi-information mr-1" /> {t('rest password')}
//                                             </button>
//                                         </Col>
//                                     </Row>
//                                 </div>
//                             </div>
//                          }
//                         {this.state.forgetPage &&
//                              <Forget t={t} />
//                         }
//                         </div>
//                     </Modal.Body>
//                 </Modal>
//             </>
//         );
//     }
// }

// export default withRouter(Login);


// import React, { Component } from 'react';
// import { Modal } from 'react-bootstrap';
// import styled from 'styled-components';
// import Cookies from 'js-cookie';
// import storage from '../../../Storage';
// import socket from '../../../Socket';
// import { Event, wait, decode, encode, sendNotfication } from '../../../Helper';
// import C from '../../../Constant';
// import GoogleBtn from "./Google";

// const StyledModal = styled(Modal)`
//   .modal-content {
//     background-color: #1e2124;
//     color: white;
//     border-radius: 18px;
//     overflow: hidden;
//   }
// `;

// const ModalHeader = styled.div`
//   background-color: #43B30B;
//   padding: 15px;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   border-bottom: 2px solid #2f7d08; // Added border
// `;

// const CloseButton = styled.button`
//   background: none;
//   border: none;
//   color: white;
//   font-size: 24px;
//   cursor: pointer;
//   display:flex;
//   justify-content:flex-end;
// `;

// const ModalBody = styled(Modal.Body)`
//   padding: 20px;
// `;

// const StyledForm = styled.form`
//   display: flex;
//   flex-direction: column;
//   gap: 15px;
// `;

// const StyledInput = styled.input`
//   background-color: #2b2f33;
//   border: 1px solid #3a3f44;
//   border-radius: 12px;
//   color: white;
//   padding: 14px;
//   &::placeholder {
//     color: #6c757d;
//   }
//   &:focus {
//     outline: none;
//     border-color: #43B30B;
//     box-shadow: 0 0 0 2px rgba(67, 179, 11, 0.2);
//   }
// `;

// const StyledButton = styled.button`
//   background-color: #8c52ff;
//   border: none;
//   border-radius: 5px;
//   color: white;
//   padding: 10px;
//   font-weight: bold;
//   cursor: pointer;
//   &:disabled {
//     opacity: 0.7;
//   }
// `;

// const SocialLoginContainer = styled.div`
//   display: flex;
//   justify-content: center;
//   gap: 10px;
//   margin-top: 20px;
// `;

// const SocialLoginButton = styled.button`
//   background-color: #2b2f33;
//   border: none;
//   border-radius: 50%;
//   width: 40px;
//   height: 40px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   cursor: pointer;
// `;


// const StyledSignInButton = styled.div`
//   font-size: 14px;
//   color:#fff !important;
//   background-color: transparent;
//   border: 1px solid #B3BEC1;
//   border-radius: 10px;
//   padding: 7px 14px;
//   font-weight: bold;
//   cursor: pointer;
//   white-space: nowrap;
// `;

// const Labeltext = styled.div`
// color:#696F79;
// font-size:14px;
// `;

// const ButtonContainer = styled.div`
//   display: flex;
//   gap: 10px;
//   justify-content:center;
// `;
// const Button = styled.button`
//   padding: 20px 30px;
//   border-radius: 30px;
//   font-weight: bold;
//   cursor: pointer;
//   transition: all 0.3s ease;

//   @media (max-width: 480px) {
//     padding: 10px 15px;
//     font-size: 14px;
//   }
// `;

// const SignInButton = styled(Button)`
//   background-color: #8b5cf6;
//   color: white;
//   border: none;
//   width: 100%;
//   max-width: 200px;

//   @media (max-width: 768px) {
//     max-width: 150px;
//   }

//   @media (max-width: 480px) {
//     max-width: 80px;
//     padding: 8px 10px;
//     font-size: 12px;
//   }

//   &:hover {
//     background-color: #7c3aed;
//   }
// `;

// const SignUpButton = styled(Button)`
//   background-color: #30353C;
//   color: white;
//   border: none;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   width: 100%;
//   max-width: 120px;

//   @media (max-width: 768px) {
//     max-width: 100px;
//   }

//   @media (max-width: 480px) {
//     max-width: 70px;
//     padding: 8px 10px;
//     font-size: 12px;
//   }

//   &:hover {
//     background-color: #374151;
//   }
// `;





// class Login extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             isLogged: storage.getKey('logged'),
//             show: this.props.show ? this.props.show : false,
//             username: '',
//             password: '',
//             status: false,
//             submitted: false,
//             disabled: false,
//             effect: 'zoomIn'
//         };
//     }

//     componentDidMount() {
//         socket.on(C.LOGIN_USER, data => this.setLogin(decode(data)));
//         Event.on('login_form', () => {
//             this.handleShow();
//         });
//     }

//     setLogin = (data) => {
//         if (data.status === true) {
//             sendNotfication('Successfully Login, Please Wait...', 'success', 'bottom-left');
//             Cookies.set("session", data.token, { expires: 14 });
//             storage.setKey('logged', true);
//             storage.setKey('token', data.token);
//             storage.setKey('name', data.name);
//             storage.setKey('avatar', data.avatar);
//             storage.setKey('email', data.email);
//             storage.setKey('credit', data.credit);
//             storage.setKey('room', data.room);
//             storage.setKey('friends', data.friends);

//             wait(500).then(() => {
//                 window.location.reload();
//             });
//         } else {
//             this.setState({ submitted: false, disabled: false });
//             sendNotfication(data.status, 'success', 'bottom-left');
//         }
//     };

//     handleShow = () => {
//         this.setState({ show: true, effect: 'zoomIn' });
//     }

//     handleClose = () => {
//         this.setState({ show: false, effect: 'zoomOut', disabled: false, status: false, submitted: false });
//     }

//     handleSubmit = async (e) => {
//         e.preventDefault();

//         this.setState({ submitted: true, disabled: true, status: 'Please wait' });

//         const { username, password } = this.state;

//         if (!(username && password)) {
//             this.setState({ disabled: false, status: false });
//             return;
//         }

//         wait(200).then(() => {
//             socket.emit(C.LOGIN_USER, encode({
//                 username: username,
//                 password: password,
//                 recaptcha: 'google'
//             }));
//         });
//     }

//     handleInputChange = (e) => {
//         this.setState({ [e.target.name]: e.target.value });
//     }

//     signup = (e) => {
//       e.preventDefault();
//       this.handleClose();
//       Event.emit('register_form');
//   }
//        forgetPass = (e) => {
//          this.setState({ forgetPage: !this.state.forgetPage })
//     };

//     render() {
//         const { show, username, password, disabled } = this.state;

//         return (
//             <>
//                 <StyledSignInButton onClick={e => this.handleShow(e)}>
//                     Sign in
//                 </StyledSignInButton>
//                 <StyledModal
//                     size="md"
//                     centered
//                     show={show}
//                     onHide={this.handleClose}
//                     aria-labelledby="login-modal"
//                     className={`animated ${this.state.effect}`}
//                 >
//                     <ModalHeader>

//                         {/* <img src="/assets/images/nanogames-logo.png" alt="NanoGames" style={{height: '30px'}} /> */}
//                         <CloseButton onClick={this.handleClose}>×</CloseButton>
//                     </ModalHeader>
//                     <ModalBody>
//                         <div className="text-center mb-4">
//                             <h3>BUILD THE BEST CRYPTO CASINO TOGETHER</h3>
//                         </div>
//                         <StyledForm onSubmit={this.handleSubmit}>
//                             <Labeltext> Enter Username</Labeltext>
//                             <StyledInput
//                                 // type="email"
//                                 name="username"
//                                 value={username}
//                                 onChange={this.handleInputChange}
//                                 placeholder="Enter Username"
//                                 // required
//                             />
//                             <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
//                                 <Labeltext> Login Password</Labeltext>
//                                 {/* <Labeltext> Forgot Password ?</Labeltext> */}
//                             </div>
//                             <StyledInput
//                                 type="password"
//                                 name="password"
//                                 value={password}
//                                 onChange={this.handleInputChange}
//                                 placeholder="Login Password"
//                                 // required
//                             />
//                              <div className="text-right">
//                                 <a href="#" onClick={this.forgetPass}>Forgot password?</a>
//                             </div>  
//                                                                          {/* <button onClick={this.forgetPass} className="btn btn-block btn-outline-dark shadow-none">
//                                                  <i className="mdi mdi-information mr-1" /> {t('rest password')}
//                                           </button> */}
//                             {/* <StyledButton type="submit" disabled={disabled}>
//                                 {disabled && <span className="spinner-grow spinner-grow-sm mr-1" role="status" aria-hidden="true"></span>}
//                                 Sign In
//                             </StyledButton> */}
//                             <ButtonContainer>
//                                 <SignInButton type="submit" >Sign In</SignInButton>
//                                 <SignUpButton onClick={(e)=>this.signup(e)}>Sign up</SignUpButton>
//                             </ButtonContainer>
//                         </StyledForm>
//                         {/* <div className="text-center mt-3">
//                             <span>Don't have an account? </span>
//                             <a href="#" onClick={this.register}>Sign up</a>
//                         </div> */}
//                         <div className="text-center mt-3 text-lg">
//                             {/* <p style={{ fontSize: '14px' }}>Log in directly with</p> */}
//                              <SocialLoginContainer>
                                
//                                    <GoogleBtn />
                                        
//                                 {/* <SocialLoginButton><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path fill="#fa455e" d="M16 0a16 16 0 110 32 16 16 0 010-32z"></path><path fill="#fff" d="M19.5 12.3c-.5-.5-1.1-.9-1.8-1a4.8 4.8 0 00-2-.2c-.9 0-1.7.4-2.3 1a5 5 0 00-2 4 5 5 0 004 4.8 5 5 0 001.6 0c.8 0 1.6-.3 2.2-.7.5-.4 1-.9 1.2-1.4l.3-.9v-.2h-4.4v-3.2h7.5l.2.1.1 1v1.2c0 .5 0 1-.2 1.6v-.1a7.4 7.4 0 01-1.4 3 7 7 0 01-3 2.4h-.1c-.6.2-1.2.4-1.9.4a8.8 8.8 0 01-1.9 0c-.8 0-1.5-.1-2.2-.4-.9-.4-1.6-.8-2.3-1.4-1-.8-1.9-2-2.4-3.2l-.5-1.9v-1.4-.1c0-.9.2-1.7.4-2.5.3-.7.7-1.4 1.2-2 1-1.4 2.5-2.5 4.3-3l1.5-.3a11.1 11.1 0 011.3 0 7.7 7.7 0 014.8 2l-.1.3-2 2h-.1z"></path></svg></SocialLoginButton> */}
//                                 {/* <SocialLoginButton><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path fill="#fff" d="M31.7 16.3a15.7 15.7 0 11-31.4 0 15.7 15.7 0 0131.4 0z"></path><path fill="#227aee" d="M0 16a16 16 0 0013.4 15.8V20.6h-4v-4.7h4v-4.4c0-2.7 2.3-5.7 6.5-5.6 1.5 0 3.4.5 3.4.5v4s-1.9-.2-3 0c-1.6.2-2 1.4-2 2v3.3h4.9l-1 4.9h-3.8v11.2A16 16 0 100 16z"></path></svg></SocialLoginButton>
//                                 <SocialLoginButton>


//                                     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path fill="#4bb2dd" d="M16 0a16 16 0 110 32 16 16 0 010-32z"></path><path fill="#fff" d="M15 20.6l3.5 2.6.9.5.3.1c.4 0 .7-.2.7-.6l.3-1.2 1.2-5.4 1.3-6.4V10a1 1 0 00-.3-.8.8.8 0 00-.9 0l-5.6 2.1-8.8 3.4-1.5.7a.5.5 0 00-.4.5c0 .3.3.4.5.4l4 1.2a.5.5 0 00.3 0l8-5 1.1-.7c.2-.1.4-.3.6 0l-.2.2-1.4 1.3q-3 2.6-5.8 5.3l-.2.3-.3 3.1v.9c.4 0 .7-.2 1-.5l1.8-1.8z"></path></svg>

//                                 </SocialLoginButton>
//                                 <SocialLoginButton><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path fill="#3d96fc" d="M32 16a16 16 0 11-32 0 16 16 0 0132 0z"></path><path fill="#fff" d="M16 14.4l5 5.2 5.1-5.1 2.2 2.1L21 24l-5-5.1-5.2 5.1-7.2-7.3 2.1-2.1 5.1 5 5.1-5zm7.9-2.4l.1.1-2.3 2.4c-4-3.8-7.5-3.9-11.5 0H10l-2.3-2.4c5.2-5 10.8-5 16-.1z"></path></svg></SocialLoginButton> */}
//                             </SocialLoginContainer> 
//                         </div>
//                     </ModalBody>
//                 </StyledModal>
//             </>
//         );
//     }
// }

// export default Login;


import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import Cookies from 'js-cookie';
import storage from '../../../Storage';
import socket from '../../../Socket';
import { Event, wait, decode, encode, sendNotfication } from '../../../Helper';
import C from '../../../Constant';
import GoogleBtn from "./Google";
import { CrossIcon } from 'lucide-react';

const StyledModal = styled(Modal)`
  .modal-content {
    background-color: #232626;
    color: white;
    border-radius: 12px;
    overflow: hidden;
    max-width: 900px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    z-index: 999;
    
    @media (min-width: 768px) {
      flex-direction: row;
    }
    
    @media (max-width: 768px) {
      padding: 16px;
      height: 100vh;
      border-radius: 0;
    }
  }
  
  .modal-dialog {
    max-width: 900px;
    margin: 0;
    height: 100vh;
    width: 100vw;
    z-index: 9999;
    
    @media (min-width: 768px) {
      margin: 1.75rem auto;
      height: auto;
      width: auto;
    }
  }
  
  &.modal {
    z-index: 1060;
  }
  
  @media (max-width: 768px) {
    margin: 0;
    padding: 0;
    
    &.modal-dialog-centered {
      min-height: 100vh;
      align-items: flex-start;
    }
  }
`;

const LeftSection = styled(Modal.Body)`
  padding: 32px;
  flex: 1;
  position: relative;
  width: 100%;
  
  @media (max-width: 768px) {
    padding: 16px;
    order: 2;
  margin-top:-398px;
  }
`;

const RightSection = styled.div`
  flex: 1;
  background-image: url('https://bc.game/modules/account2/assets/bg-b12a40dc.png');
  background-size: cover;
  background-position: center;
  position: relative;
  min-height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px;
  object-fit: cover;
  
  @media (min-width: 768px) {
    min-height: 450px;
    background-position: center top;
    overflow: hidden;
    background-size: 100% auto;
  }
  
  @media (max-width: 768px) {
    order: 1;
    padding: 12px;
    height: 90px;
    min-height: auto;
    background: url('https://bc.game/modules/account2/assets/bg_m-402471db.png') no-repeat;
    background-position: top;
    background-size: contain;
    justify-content: flex-end;
    
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 50%;
      background: linear-gradient(to bottom, rgba(35, 38, 38, 0), rgba(35, 38, 38, 1));
      pointer-events: none;
    }
  }
`;

const RightContent = styled.div`
  position: relative;
  z-index: 1;
  color: white;
`;

const StayUntamed = styled.div`
  h2 {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 16px;
    color: white;
    text-align: center;
    
    @media (max-width: 768px) {
      font-size: 20px;
      margin-bottom: 8px;
    }
  }

  p {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.8);
    text-align: center;
    
    @media (max-width: 768px) {
      font-size: 12px;
    }
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const CloseButton = styled.button`
  position: fixed;
  right: 16px;
  top: 16px;
  background: rgba(255, 255, 255, 0.1);
  border: none;
  color: white;
  font-size: 18px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
  
  @media (min-width: 768px) {
    position: absolute;
  }
  
  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
`;

const Logo = styled.div`
  margin-bottom: 32px;
  
  img {
    height: 36px;
  }

  @media (max-width: 768px) {
    margin-bottom: 16px;
    
    img {
      height: 28px;
    }
  }
`;

const Title = styled.h2`
  font-size: 24px;
  color: white;
  margin-bottom: 32px;

  @media (max-width: 768px) {
    font-size: 18px;
    margin-bottom: 20px;
  }
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: 768px) {
    gap: 12px;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StyledInput = styled.input`
  background-color: #111315;
  border: 1px solid #272B30;
  border-radius: 12px;
  color: white;
  padding: 12px 16px;
  width: 100%;
  font-size: 15px;

  @media (max-width: 768px) {
    padding: 10px 12px;
    font-size: 13px;
    border-radius: 8px;
  }

  &::placeholder {
    color: #6F767E;
  }

  &:focus {
    outline: none;
    border-color: #27ED87;
  }
`;

const PasswordWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const PasswordToggle = styled.button`
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: #6F767E;
  cursor: pointer;
`;

const ForgotPassword = styled.a`
  color: #6F767E;
  text-decoration: none;
  font-size: 14px;
  text-align: right;
  display: block;
  margin-top: -12px;
  
  @media (max-width: 768px) {
    font-size: 12px;
    margin-top: -8px;
  }
  
  &:hover {
    color: #27ED87;
  }
`;

const SignInButton = styled.button`
  background: linear-gradient(to bottom, #4AE54A, #94E874);
  border: none;
  border-radius: 12px;
  color: black !important;
  padding: 12px 16px;
  font-weight: 600;
  font-size: 15px;
  width: 100%;
  cursor: pointer;
  margin-top: 12px;

  @media (max-width: 768px) {
    padding: 10px 12px;
    font-size: 14px;
    margin-top: 8px;
    border-radius: 8px;
  }

  &:hover {
    background-color: #00A06D;
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;

const Divider = styled.div`
  display: flex;
  align-items: center;
  margin: 24px 0;
  color: #6F767E;
  font-size: 14px;
  
  &::before, &::after {
    content: "";
    flex: 1;
    height: 1px;
    background: #272B30;
    margin: 0 16px;
  }

  @media (max-width: 768px) {
    margin: 16px 0;
    font-size: 12px;
  }
`;

const SocialLoginContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 12px;
  
  @media (max-width: 768px) {
    gap: 8px;
  }
`;

const SocialButton = styled.button`
  background: #111315;
  border: 1px solid #272B30;
  border-radius: 12px;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  
  @media (max-width: 768px) {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    
    svg {
      width: 18px;
      height: 18px;
    }
  }
  
  &:hover {
    background: #191B1D;
  }

  svg {
    width: 24px;
    height: 24px;
    fill: #6F767E;
  }
`;

const SignUpPrompt = styled.div`
  text-align: center;
  margin-top: 24px;
  color: #6F767E;
  font-size: 14px;

  @media (max-width: 768px) {
    margin-top: 16px;
    font-size: 12px;
  }

  a {
    color: #27ED87;
    text-decoration: none;
    font-weight: 600;
    margin-left: 8px;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const ModalClose = styled.div`
 display:none;
  @media (max-width: 768px) {
  display:flex;
   height:30px;
   width:30px;
   justify-content:center;
   align-items:center;
   border-radius:20%;
   background-color:#2D6A4D;
   position:absolute;
   padding:12px;
   top:-550px;;
   left:299px;
   z-index:9999;

  }
`;

const StyledSignInButton = styled.div`
  font-size: 14px;
  color: #fff !important;
  background-color: transparent;
  border: 1px solid #B3BEC1;
  border-radius: 10px;
  padding: 7px 14px;
  font-weight: bold;
  cursor: pointer;
  white-space: nowrap;

  @media (max-width: 768px) {
    width: 100%;
    text-align: center;
    font-size: 13px;
    padding: 6px 12px;
    border-radius: 8px;
  }
`;
class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLogged: storage.getKey('logged'),
            show: this.props.show ? this.props.show : false,
            username: '',
            password: '',
            status: false,
            submitted: false,
            disabled: false,
            effect: 'zoomIn',
            forgetPage: false
        };
    }

    componentDidMount() {
        socket.on(C.LOGIN_USER, data => this.setLogin(decode(data)));
        Event.on('login_form', () => {
            this.handleShow();
        });
    }

    setLogin = (data) => {
        if (data.status === true) {
            sendNotfication('Successfully Login, Please Wait...', 'success', 'bottom-left');
            Cookies.set("session", data.token, { expires: 14 });
            storage.setKey('logged', true);
            storage.setKey('token', data.token);
            storage.setKey('name', data.name);
            storage.setKey('avatar', data.avatar);
            storage.setKey('email', data.email);
            storage.setKey('credit', data.credit);
            storage.setKey('room', data.room);
            storage.setKey('friends', data.friends);

            wait(500).then(() => {
                window.location.reload();
            });
        } else {
            this.setState({ submitted: false, disabled: false });
            sendNotfication(data.status, 'success', 'bottom-left');
        }
    };

    handleShow = () => {
        this.setState({ show: true, effect: 'zoomIn' });
    }

    handleClose = () => {
        this.setState({ 
            show: false, 
            effect: 'zoomOut', 
            disabled: false, 
            status: false, 
            submitted: false,
            forgetPage: false 
        });
    }

    handleSubmit = async (e) => {
        e.preventDefault();

        this.setState({ submitted: true, disabled: true, status: 'Please wait' });

        const { username, password } = this.state;

        if (!(username && password)) {
            this.setState({ disabled: false, status: false });
            return;
        }

        wait(200).then(() => {
            socket.emit(C.LOGIN_USER, encode({
                username: username,
                password: password,
                recaptcha: 'google'
            }));
        });
    }

    handleInputChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    signup = (e) => {
        e.preventDefault();
        this.handleClose();
        Event.emit('register_form');
    }

    forgetPass = (e) => {
        e.preventDefault();
        this.setState({ forgetPage: !this.state.forgetPage });
    };

    render() {
        const { show, username, password, disabled, forgetPage } = this.state;

        return (
          <>
          <StyledSignInButton onClick={this.handleShow}>
              Sign in
          </StyledSignInButton>
          <StyledModal
              size="md"
              centered
              show={show}
              onHide={this.handleClose}
              aria-labelledby="login-modal"
              className={`animated ${this.state.effect}`}
          >
              <CloseButton onClick={this.handleClose}>×</CloseButton>
              <RightSection>
                  <RightContent>
                    <ModalClose  onClick={this.handleClose}>
                      x
                    </ModalClose>
                  </RightContent>
                  <StayUntamed>
                      {/* <h2>Stay Untamed</h2> */}
                      {/* <p>Sign Up & Get Welcome Bonus</p> */}
                  </StayUntamed>
                
              </RightSection>
              <LeftSection>
                  <Title>Sign In</Title>
                  {!forgetPage ? (
                      <>
                          <StyledForm onSubmit={this.handleSubmit}>
                              <InputWrapper>
                                  <StyledInput
                                      type="text"
                                      name="username"
                                      value={username}
                                      onChange={this.handleInputChange}
                                      placeholder="Email / Phone Number"
                                  />
                              </InputWrapper>
                              <InputWrapper>
                                  <PasswordWrapper>
                                      <StyledInput
                                          type="password"
                                          name="password"
                                          value={password}
                                          onChange={this.handleInputChange}
                                          placeholder="Password"
                                      />
                                      <PasswordToggle type="button">
                                          <svg viewBox="0 0 24 24" width="20" height="20">
                                              <path fill="currentColor" d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"/>
                                          </svg>
                                      </PasswordToggle>
                                  </PasswordWrapper>
                              </InputWrapper>
                              <ForgotPassword href="#" onClick={this.forgetPass}>
                                  Forgot your password?
                              </ForgotPassword>
                              <SignInButton type="submit" disabled={disabled}>
                                  {disabled ? 'Signing in...' : 'Sign In'}
                              </SignInButton>
                          </StyledForm>
                          <Divider>or continue with</Divider>
                          <SocialLoginContainer>
                              <GoogleBtn />
                              <SocialButton>
                                  <svg viewBox="0 0 24 24">
                                      <path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z"/>
                                  </svg>
                              </SocialButton>
                              <SocialButton>
                                  <svg viewBox="0 0 24 24">
                                      <path d="M22.162 5.656a8.384 8.384 0 0 1-2.402.658A4.196 4.196 0 0 0 21.6 4c-.82.488-1.719.83-2.656 1.015a4.182 4.182 0 0 0-7.126 3.814 11.874 11.874 0 0 1-8.62-4.37 4.168 4.168 0 0 0-.566 2.103c0 1.45.738 2.731 1.86 3.481a4.168 4.168 0 0 1-1.894-.523v.052a4.185 4.185 0 0 0 3.355 4.101 4.21 4.21 0 0 1-1.89.072A4.185 4.185 0 0 0 7.97 16.65a8.394 8.394 0 0 1-6.191 1.732 11.83 11.83 0 0 0 6.41 1.88c7.693 0 11.9-6.373 11.9-11.9 0-.18-.005-.362-.013-.54a8.496 8.496 0 0 0 2.087-2.165z"/>
                                  </svg>
                              </SocialButton>
                          </SocialLoginContainer>
                          <SignUpPrompt>
                              Don't have an account?
                              <a href="#" onClick={this.signup}>Sign up</a>
                          </SignUpPrompt>
                      </>
                  ) : (
                      <StyledForm onSubmit={this.handleSubmit}>
                          <Title>Reset Password</Title>
                          <InputWrapper>
                              <StyledInput
                                  type="email"
                                  name="email"
                                  placeholder="Enter your email"
                                  onChange={this.handleInputChange}
                              />
                          </InputWrapper>
                          <SignInButton type="submit" disabled={disabled}>
                              {disabled ? 'Sending...' : 'Reset Password'}
                          </SignInButton>
                          <SignUpPrompt>
                              Remember your password?
                              <a href="#" onClick={this.forgetPass}>Sign in</a>
                          </SignUpPrompt>
                      </StyledForm>
                  )}
              </LeftSection>
          </StyledModal>
      </>

        );
    }
}

export default Login;