import React from 'react';
import styled from 'styled-components';
import BonusCards from './BonusCards';

const PromotionContainer = styled.div`
  background: linear-gradient(111.21deg, rgb(210, 236, 39) -88.74%, rgb(50, 55, 56) 46.65%);
  color: #fff;
  padding: 20px 30px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 15px;
    gap: 20px;
  }
`;

const HeaderContent = styled.div`
  width: 300px;

  @media (max-width: 768px) {
    width: 100%;
    text-align: left;
  }
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const BonusAmount = styled.div`
  font-size: 36px;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 28px;
    margin: 10px 0;
  }
`;

const DepositNowButton = styled.div`
  background: linear-gradient(90deg, rgb(36, 238, 137), rgb(159, 232, 113));
  color: black;
  border-radius: 8px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  display: block; /* Fixed missing semicolon */
  
  @media (max-width: 768px) {
    display: none; /* Fixed missing semicolon */
  }
`;

const DepositNowButtonMobile = styled.div`
  background: linear-gradient(90deg, rgb(36, 238, 137), rgb(159, 232, 113));
  color: black;
  border-radius: 8px;
  padding: 8px 16px;
  font-size: 14px;
  width:100%;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  display: none; /* Fixed missing semicolon */
  
  @media (max-width: 768px) {
    display: block; /* Fixed missing semicolon */
  }
`;


const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
    padding: 10px;
    width: 100%;
  }
`;

const BonusCard = styled.div`
  background-image: url(${props => props.backgroundImage}) !important;
  background-size: 100% 100%;
  padding: 1rem;
  text-align: center;
  position: relative;

  @media (max-width: 768px) {
    padding: 0.5rem;
  }
`;

const BonusImage = styled.img`
  width: 128px;
  height: 125px;
  margin: 0.5rem 0;

  @media (max-width: 768px) {
    width: 80px;
    height: 80px;
  }
`;

const BonusImage1 = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  background-image: url(${props => props.src});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    width: 25px;
    height: 25px;
    top: 5px;
  }
`;

const CenteredText = styled.div`
  color: white;
  font-size: 0.75rem;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 0.6rem;
  }
`;

const BonusPercentage = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  margin: 5px 0;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const ButtonContainer = styled.div`
  background-color: #323738;
  padding: 6px 4px;
  border-radius: 8px;
  font-size: 14px;

  @media (max-width: 768px) {
    font-size: 12px;
    padding: 4px 2px;
  }
`;

const Promotion = () => {
  const bonusCards = [
    {
      backgroundImage: "https://bc.game/modules/bonus2/assets/d-1-b26450d0.png",
      stepIcon: "https://bc.game/modules/bonus2/assets/step-i-f-fc26a955.png",
      giftBox: "https://bc.game/modules/bonus2/assets/d-1-box-43867056.png",
      bonus: "180%",
      step: "01",
      text: "First Deposit"
    },
    {
      backgroundImage: "https://bc.game/modules/bonus2/assets/d-2-18e324d3.png",
      stepIcon: "https://bc.game/modules/bonus2/assets/step-i-s-c36b403b.png",
      giftBox: "https://bc.game/modules/bonus2/assets/d-2-box-0395581a.png",
      bonus: "240%",
      step: "02",
      text: "Second Deposit"
    },
    {
      backgroundImage: "https://bc.game/modules/bonus2/assets/d-3-63590e37.png",
      stepIcon: "https://bc.game/modules/bonus2/assets/step-i-t-4b3271c5.png",
      giftBox: "https://bc.game/modules/bonus2/assets/d-3-box-c15095d8.png",
      bonus: "300%",
      step: "03",
      text: "Third Deposit"
    },
    {
      backgroundImage: "https://bc.game/modules/bonus2/assets/d-4-d6bc6dc3.png",
      stepIcon: "https://bc.game/modules/bonus2/assets/step-i-t-4b3271c5.png",
      giftBox: "https://bc.game/modules/bonus2/assets/d-4-box-f5e64f9d.png",
      bonus: "360%",
      step: "04",
      text: "Fourth Deposit"
    }
  ];

  return (

    <>
   
    <PromotionContainer>
      <HeaderContent>
        <Title>Great Deposit Bonus in </Title>
        <Title>casino or Sports</Title>
        <BonusAmount>UP TO100% +
        400 FS/20 FB</BonusAmount>
        <DepositNowButton>Deposit Now</DepositNowButton>
      </HeaderContent>
      <CardsContainer>
        {bonusCards.map((card, index) => (
          <BonusCard key={index} backgroundImage={card.backgroundImage}>
            <BonusImage1 src={card.stepIcon}>
              <CenteredText>{card.step}</CenteredText>
            </BonusImage1>
            <BonusImage src={card.giftBox} alt={`Gift Box ${index + 1}`} />
            <BonusPercentage>{card.bonus} BONUS</BonusPercentage>
            <ButtonContainer>{card.text}</ButtonContainer>
          </BonusCard>
        ))}
      </CardsContainer>
      <DepositNowButtonMobile>Deposit Now</DepositNowButtonMobile>
    </PromotionContainer>

    <BonusCards/>

    </>
  );
};

export default Promotion;