import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ChevronRight, ChevronDown, Search, Info, FileQuestion } from "lucide-react";
import storage from "../../../../Storage";
import C from "../../../../Constant";
import coins from "../../../coins";
import socket from "../../../../Socket";
import { Col, Dropdown } from "react-bootstrap";
import {
    __, wait, decode, encode, forceSatoshiFormat, Event,
    isMobile,
    sendNotfication,
    getUID,
} from '../../../../Helper';
import axios from 'axios';


const WithdrawContainer = styled.div`
  margin: 0 auto;
  padding: 24px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`;

const Row1 = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
`;

const Label1 = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  
  ${props => props.isGreen && `
    color: #20EE89;
    font-size: 16px;
    font-weight: 500;
  `}
  
  ${props => props.isGray && `
    color: #9ba1a6;
    font-size: 14px;
  `}
`;

const Amount = styled.div`
${props => props.isGreen && `
    color: #20EE89;

   
  `}
  font-size: ${props => props.large ? '16px' : '14px'};
  font-weight: ${props => props.bold ? '600' : '400'};
`;

const IconButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.7;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  
  &:hover {
    opacity: 1;
  }
`;



const Container = styled.div`
  padding: 8px;
  color: #ffffff;
`;
const DropdownContainer = styled.div`
  position: relative;
  margin-bottom: 16px;
  flex:1;
`;

const DropdownButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding:6px 10px;
  background: #292D2E;
  border: 1px solid rgb(58 65 66);
  border-radius: 8px;
  color: #ffffff;
  cursor: pointer;
  font-size: 16px;
`;

const DropdownPanel = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: #292D2E;
  border: 1px solid rgb(58 65 66);
  border-radius: 8px;
  margin-top: 4px;
  max-height: 400px;
  overflow-y: auto;
  z-index: 1000;
  padding:10px 12px;
`;

const SearchContainer = styled.div`
  position: relative;
  padding:0px;
    border-radius: 8px;
  border: 1px solid rgb(58 65 66);
  margin-top:12px;
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 6px 12px 6px 40px;
  background: transparent;
  border: none;
  border-radius: 8px;
  color: #ffffff;
  font-size: 16px;
  &::placeholder {
    color: #fff;
    opacity:0.6;
  }
`;

const Input = styled.input`
width:100%;
  padding:6px 10px;
  background: #292D2E;
  margin-bottom:12px;
  border: 1px solid rgb(58 65 66);
  border-radius: 8px;
  color: #ffffff;
  font-size: 14px;
  &::placeholder {
    color: #fff;
    opacity:0.4;
  }
`;
const DepositButton = styled.div`
  margin-top: auto;
flex:1;
padding:7px 9px;
  display:flex;
  justify-content:center;
  align-items:center;
  background: linear-gradient(to right, #9FE871, #24EE89);
  color: black !important;
  border: none;
  border-radius: 0.5rem;
  font-weight: 600;
  cursor: pointer;
  
  &:hover {
    opacity: 0.9;
  }
`;
const SearchIcon = styled.div`
  position: absolute;
  left: 14px;
  top: 49%;
  transform: translateY(-50%);
  color: #fff;
`;

const CoinOption = styled.div`
  display: flex;
  align-items: center;
  padding: 14px;
  cursor: pointer;
  border-radius:12px;
  &:hover {
    background: #323738;
  }
  ${props => props.isSelected && `
    background: #323738;
  `}
`;

const CoinImage = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 12px;
`;

const CoinName = styled.div`
  font-size: 16px;
  font-weight: bold;
 
`;

const CoinBalance = styled.span`
  margin-left: auto;
  color: #8E9293;
   display:none;
`;


// const CoinName = styled.span`
//   font-size: 16px;
//   font-weight: 500;
// `;

const TokenListContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 16px;
`;

const TokenButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background: #394142;
  border-radius: 100px;
  border: none;
  cursor: pointer;
  color: #ffffff;
  transition: background 0.2s ease;
  &:hover {
    background: #363A3B;
  }
`;

const TokenIcon = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;
`;

const TokenName = styled.div`
  font-size: 14px;
  font-weight: 500;
`;

const MoreButton = styled(TokenButton)`
  padding: 8px 12px;
`;

const FooterText = styled.div`
  color: #ffffff;
  margin: 18px 0;
`;

const AddLink = styled.span`
  color: #20EE89;
  cursor: pointer;
`;

const QRCodeContainer = styled.div`
  width: 120px;
  height: 120px;
  background-color: #fff;
  border-radius: 4px;
  overflow: hidden;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Label = styled.div`
  color: #9ba1a6;
  font-size: 14px;
`;

const AddressContainer = styled.div`
  background-color: #2c3235;
  padding: 12px 16px;
  border-radius: 6px;
  color: #fff;
  font-family: monospace;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const CopyButton = styled.button`
  background-color: #3c4246;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  transition: background-color 0.2s;
  white-space: nowrap;
  
  &:hover {
    background-color: #4c5256;
  }
`;

const CopyIcon = styled.span`
  display: inline-block;
  width: 16px;
  height: 16px;
  background-color: currentColor;
  mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z' /%3E%3C/svg%3E");
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
`;



const CodeContainer = styled.div`
  border-radius: 8px;
  display: flex;
  gap: 20px;
  align-items: center;
  margin: 12px auto;
`;
const WarningContainer = styled.div`
  background-color: rgba(52, 199, 89, 0.1);
  border-radius: 8px;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 16px auto;
  margin-bottom: 16px;
`;

const IconWrapper = styled.div`
  width: 20px;
  height: 20px;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const WarningText = styled.span`
  color: #fff;
  font-size: 13px;
  font-weight: 500;
`;

const BonusContainer = styled.div`
  background-color: rgba(180, 155, 100, 0.2);
  border-radius: 8px;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 18px auto;
  margin-bottom: 16px;
`;

const IconWrapper1 = styled.div`
  width: 28px;
  height: 28px;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  flex-wrap: wrap;
`;

const BaseText = styled.span`
  color: #ffffff;
  font-size: 15px;
  font-weight: 600;
`;

const HighlightedText = styled(BaseText)`
  color: #FFA500;
`;


const cryptoOptions = [
    { id: 'dot', name: 'DOT', iconClass: 'dot' },
    { id: 'link', name: 'LINK', iconClass: 'link' },
    { id: 'dai', name: 'DAI', iconClass: 'dai' },
    { id: 'usdc', name: 'USDC', iconClass: 'usdc' },
    { id: 'xmr', name: 'XMR', iconClass: 'xmr' },
];

const tokens = [
    { id: 'eth', name: 'ETH', icon: '/assets/images/ETH.png' },
    { id: 'btc', name: 'BTC', icon: '/assets/images/BTC.png' },
    { id: 'usdt', name: 'USDT', icon: '/assets/images/USDT.png' },
    { id: 'usdc', name: 'USDC', icon: '/assets/images/USDC.png' },
    { id: 'doge', name: 'DOGE', icon: '/assets/images/DOGE.png' },
];

const SkylaWithdraw = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenNetwork, setIsOpenNetwork] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedNetwork, setSelctedNetwork] = useState("ETherum")

    // Coin and credit state
    const [credits, setCredits] = useState({});
    const [selectedCoin, setSelectedCoin] = useState(storage.getKey('coin') || "INR");
    const [activeCoins, setActiveCoins] = useState(
        storage.getKey('active_coins')?.split(',').map(String) || ['1']
    );

    // Main component state
    const [state, setState] = useState({
        currentCoin: storage.getKey('coin') || "INR",
        credits: {},
        list: [],
        content: [],
        wallet: "btc",
        deposit: true,
        slide: false,
        height: 573,
        margin: "mt-1",
        amount: "",
        coinId: "",
        networks: [],
        selectedNetwork: "",
        qrString: "",
        coinName: "",
        logoUrl: "",
        isLoading: false,
        isCopied: false,
        depositAddress: "",
        depositAmount: "",
        showDepositDetails: false
    });
    const [copied, setCopied] = React.useState(false);
    const address = "0x5e6E82fc1117AE06c8b1230164284Ec5d0141Df";

    const handleCopy = () => {
        navigator.clipboard.writeText(address);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
    };

    // Utility Functions
    const getCoinNameByNetwork = useCallback((network, coinInfo, defaultCoin) => {
        if (networkCoinMap[network]) {
            return networkCoinMap[network];
        }
        if (coinInfo && coinInfo.id) {
            return coinInfo.id;
        }
        return defaultCoin.toLowerCase();
    }, []);

    // Socket and Credit Management
    const getCreditAndCoins = useCallback((data) => {
        const { credit } = data;
        setState(prev => ({ ...prev, credits: credit }));
        setUpCoins();
    }, []);

    const setUpCoins = useCallback(() => {
        if (isMobile()) {
            setState(prev => ({ ...prev, height: 685, margin: "mt-1" }));
        }

        const newList = coins.reduce((acc, item, i) => {
            if (item.preffix === "NC") return acc;

            const credit = forceSatoshiFormat(
                state.credits[__.lowerCase(item.preffix)]
            );

            const listItem = (
                <Dropdown.Item
                    key={__.toString(i)}
                    as="button"
                    className="animated fadeIn"
                    onClick={() => loadCoinId(item.preffix)}
                >
                    <span>
                        <img
                            src={`/assets/images/${item.image}`}
                            className="img-fluid mini-coin mr-1"
                            alt="Coin"
                        />
                        {item.preffix}
                    </span>
                </Dropdown.Item>
            );

            return [...acc, listItem];
        }, []);

        setState(prev => ({ ...prev, list: newList }));
    }, [state.credits, isMobile]);

    // Archive Management
    const showArchive = useCallback(() => {
        if (state.slide) Event.emit("deposit_archive_back");

        setState(prev => ({ ...prev, slide: !prev.slide }));

        wait(300).then(() => {
            setState(prev => ({ ...prev, deposit: !prev.deposit }));
        });

        wait(300).then(() => {
            loadCoinId("BTC");
        });
    }, [state.slide]);

    // Coin Loading and Management
    const loadCoinId = useCallback(async (coin) => {
        if (coin.toUpperCase() === 'INR') {
            console.log("Coin is INR, skipping loadCoinId process.");
            return;
        }

        setState(prev => ({
            ...prev,
            currentCoin: coin,
            coinId: "",
            networks: [],
            selectedNetwork: "",
            isLoading: true,
            coinName: "",
            logoUrl: ""
        }));

        console.log("coin", coin)

        try {
            const response = await axios.post('https://api.xoxowin.com/getCoinDetails', {
                symbol: coin.toUpperCase()
            });


            console.log("infor", response)

            const { coinId, networks, coinFullName, logoUrl } = response.data;
            let networksList = Object.keys(networks);

            const coinInfo = coinsList.find(item => item.symbol === coin.toUpperCase());
            let selectedNetwork = networksList[0] || "";
            let coinName = coinInfo ? coinInfo.id : coin.toLowerCase();

            if (coin.toUpperCase() === 'USDT') {
                networksList = ['ETH', 'TRX', 'BSC'];
            }

            coinName = getCoinNameByNetwork(selectedNetwork, coinInfo, coin);

            setState(prev => ({
                ...prev,
                coinId,
                networks: networksList,
                selectedNetwork,
                isLoading: false,
                coinName,
                logoUrl
            }));
        } catch (error) {
            console.error("Error fetching coin details:", error);
            sendNotfication("Error fetching coin information", "error", "top-right");
            setState(prev => ({ ...prev, isLoading: false }));
        }
    }, [getCoinNameByNetwork]);

    // Payment Processing
    const initiateDeposit = async () => {
        if (!state.amount || state.amount <= 0) {
            sendNotfication("Please enter a correct amount.", "error", "top-right");
            return;
        }

        setState(prev => ({ ...prev, isLoading: true, qrString: "" }));
        const payload = {
            coinId: 1161,//hardcode value
            price: state.amount,
            orderId: `order${Date.now()}`,
            chain: selectedNetwork,
            generateCheckoutURL: true,
            returnUrl: "https://xoxowin.coms/",
            userid: getUID.toString()
        }

        console.log("paylod", payload)


        try {
            const response = await axios.post('https://api.xoxowin.com/createDeposit', payload

            );

            const { data } = response.data;

            if (data.address) {
                const coinName = getCoinNameByNetwork(state.selectedNetwork, null, state.coinName);

                setState(prev => ({
                    ...prev,
                    depositAddress: data.address,
                    depositAmount: state.amount,
                    showDepositDetails: true,
                    isLoading: false,
                    qrString: `${coinName}:${data.address}?amount=${state.amount}`
                }));
            } else {
                sendNotfication("Deposit initiation failed", "error", "top-right");
                setState(prev => ({ ...prev, isLoading: false }));
            }
        } catch (error) {
            console.error("Error initiating deposit:", error);
            sendNotfication("Error initiating deposit", "error", "top-right");
            setState(prev => ({ ...prev, isLoading: false }));
        }
    };

    const initiateUPIPayment = async () => {
        if (state.currentCoin !== 'INR') {
            console.log("UPI payment can only be initiated when the current coin is INR.");
            return;
        }

        if (!state.amount || state.amount <= 0) {
            sendNotfication("Please enter a correct amount.", "error", "top-right");
            return;
        }

        setState(prev => ({ ...prev, isLoading: true }));

        try {
            const response = await axios.post('https://api.xoxowin.com/createorderupi', {
                uid: getUID.toString(),
                amount: state.amount
            });

            const { data } = response.data;

            if (data && response.data.status) {
                const { payment_url } = data;
                const phonepay = data.upi_intent.phonepe_link;

                setState(prev => ({
                    ...prev,
                    depositAmount: state.amount,
                    isLoading: false,
                    qrString: phonepay
                }));

                window.location.href = payment_url;
            } else {
                sendNotfication(response.data.msg || "Error creating UPI order", "error", "top-right");
            }
        } catch (error) {
            console.error("Error initiating UPI payment:", error);
            sendNotfication("Error initiating UPI payment", "error", "top-right");
        } finally {
            setState(prev => ({ ...prev, isLoading: false }));
        }
    };

    // Utility Functions
    const copyToClipboard = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
            setState(prev => ({ ...prev, isCopied: true }));
            sendNotfication("Address copied to clipboard", "success", "top-right");
        } catch (err) {
            console.error('Failed to copy: ', err);
        }
    };

    const handleAmountChange = (e) => {
        setState(prev => ({ ...prev, amount: e.target.value }));
    };

    const handleNetworkChange = (e) => {
        setState(prev => ({ ...prev, selectedNetwork: e.target.value }));
    };

    // Socket Connection Effect
    useEffect(() => {
        const token = storage.getKey('token');

        if (token) {
            socket.emit(C.CREDIT, encode({ token, coin: selectedCoin }));
        }

        const handleCreditData = (data) => {
            const decodedData = decode(data);
            const { credit } = decodedData;
            setCredits(credit);

            const currentCoin = __.lowerCase(selectedCoin);
            const currentCredit = forceSatoshiFormat(credit[currentCoin]);
            storage.setKey('credit', currentCredit);
        };

        const handleCreditUpdate = (data) => {
            const decodedData = decode(data);
            const { value, coin: updatedCoin } = decodedData;
            const formattedValue = forceSatoshiFormat(value);

            setCredits(prev => ({
                ...prev,
                [__.lowerCase(updatedCoin)]: value
            }));

            if (__.lowerCase(updatedCoin) === __.lowerCase(selectedCoin)) {
                storage.setKey('credit', formattedValue);
            }
        };

        socket.on(C.CREDIT, handleCreditData);
        socket.on(C.UPDATE_CREDIT, handleCreditUpdate);

        return () => {
            socket.off(C.CREDIT, handleCreditData);
            socket.off(C.UPDATE_CREDIT, handleCreditUpdate);
        };
    }, [selectedCoin]);

    // Initial Setup Effect
    useEffect(() => {
        socket.emit(C.CREDIT, encode({ coin: storage.getKey('coin') || "INR" }));
        socket.on(C.CREDIT, (data) => getCreditAndCoins(decode(data)));

        Event.on("deposit_archive", showArchive);

        wait(400).then(() => {
            loadCoinId(storage.getKey('coin') || "INR");
        });

        return () => {
            socket.off(C.CREDIT);
        };
    }, [storage.getKey('coin') || "INR", getCreditAndCoins, loadCoinId, showArchive]);

    // Active Coins Initialization Effect
    useEffect(() => {
        if (!storage.getKey('active_coins')) {
            const defaultActiveCoins = ['1', '2', '3', '4', '5', '6', '7'];
            storage.setKey('active_coins', defaultActiveCoins.join(','));
            setActiveCoins(defaultActiveCoins);
        }
    }, []);

    // Click Outside Handler Effect
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (isOpen && !event.target.closest('.dropdown-container')) {
                setIsOpen(false);
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [isOpen]);

    // Coin Selection Handler
    const handleCoinSelect = async (coinName) => {
        if (storage.getKey('coin') === coinName) {
            setIsOpen(false);
            return;
        }

        storage.setKey('coin', coinName);
        const credit = forceSatoshiFormat(credits[__.lowerCase(coinName)] || 0);
        storage.setKey('credit', credit);

        setSelectedCoin(coinName);
        setIsOpen(false);

        try {
            await wait(200);
            Event.emit('coin_changed', coinName);
        } catch (error) {
            console.error('Error during coin selection:', error);
        }
    };

    // Filtered Coins Logic
    const filteredCoins = coins
        .filter(coin => {
            if (!activeCoins.includes(coin.id.toString())) return false;
            return coin.preffix.toLowerCase().includes(searchTerm.toLowerCase());
        })
        .sort((a, b) => {
            if (a.preffix === 'BTC') return -1;
            if (b.preffix === 'BTC') return 1;
            return 0;
        });

    const selectedCoinData = coins.find(coin => coin.preffix === selectedCoin);
    //statically netwrosk added later will remove it
    const networkDetails = [
        "Etherum", "BEP20"
    ]
    return (
        <Container>

            <div style={{ display: "flex", gap: "5px", width: "100%" }}>
                <div style={{ flex: '1' }}>
                    <div style={{ color: "#B3BEC1", margin: "4px 0" }}>Deposit Currency</div>
                    <DropdownContainer>
                        <DropdownButton onClick={(e) => {
                            e.stopPropagation();
                            setIsOpen(!isOpen);
                        }}>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                                <CoinImage
                                    src={`/assets/images/${selectedCoinData?.image}`}
                                    alt={selectedCoin}
                                />
                                <CoinName>{selectedCoin}</CoinName>
                            </div>
                            <ChevronDown size={20} />
                        </DropdownButton>

                        {isOpen && (
                            <DropdownPanel onClick={e => e.stopPropagation()}>
                                <SearchContainer>
                                    <SearchIcon>
                                        <Search size={20} />
                                    </SearchIcon>
                                    <SearchInput
                                        placeholder="Search coins..."
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        autoFocus
                                    />
                                </SearchContainer>

                                {filteredCoins.map((coin) => (
                                    <CoinOption
                                        key={coin.id}
                                        onClick={() => handleCoinSelect(coin.preffix)}
                                        isSelected={selectedCoin === coin.preffix}
                                    >
                                        <CoinImage src={`/assets/images/${coin.image}`} alt={coin.preffix} />
                                        <CoinName>{coin.preffix}</CoinName>
                                        <CoinBalance>
                                            {forceSatoshiFormat(credits[__.lowerCase(coin.preffix)] || 0)}
                                        </CoinBalance>
                                    </CoinOption>
                                ))}
                            </DropdownPanel>
                        )}
                    </DropdownContainer>
                </div>


            </div>
            <div style={{ flex: '1' }}>
                <div style={{ color: "#B3BEC1", margin: "4px 0" }}>
                    Choose Network
                </div>
                <DropdownContainer>
                    <DropdownButton onClick={(e) => {
                        e.stopPropagation();
                        setIsOpenNetwork(!isOpenNetwork);
                    }}>


                        <CoinName>{selectedNetwork}</CoinName>

                        <ChevronDown size={20} />
                    </DropdownButton>

                    {isOpenNetwork && (
                        <DropdownPanel onClick={e => e.stopPropagation()}>


                            {networkDetails?.map((network) => (
                                <CoinOption
                                    key={network}
                                    onClick={() => {
                                        setSelctedNetwork(network)
                                        setIsOpenNetwork(false)
                                    }}
                                    isSelected={selectedNetwork === network}
                                >

                                    <CoinName>{network}</CoinName>

                                </CoinOption>
                            ))}
                        </DropdownPanel>
                    )}
                </DropdownContainer>
            </div>
            <div style={{ flex: "1", width: "100%" }}>
                <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>

                    <div style={{ color: "#B3BEC1", margin: "4px 0" }}>
                        Withdrawl Amount
                    </div>
                    <div style={{ color: "#B3BEC1", margin: "4px 0" }}>
                        00.002
                    </div>
                </div>

            </div>
            <div style={{ width: "100%" }}>
                <Input
                    value={state.amount}
                    onChange={(e) => setState((prev) => ({ ...prev, amount: e.target.value }))}
                    placeholder="Enter Withdraw Amount"
                />
            </div>

            <WithdrawContainer>
                <Row1>
                    <Label1 isGreen>Withdraw amount</Label1>
                    <Amount isGreen large bold>0.00 {selectedCoin}</Amount>
                </Row1>

                <Row1>
                    <Label1 isGray>
                        Fee:
                        <IconButton>
                            <FileQuestion />
                        </IconButton>
                    </Label1>
                    <Amount>0.289249 {selectedCoin}</Amount>
                </Row1>

                <Row1>
                    <Label1 isGray>Total Withdraw amount</Label1>
                    <Amount>0.00 {selectedCoin}</Amount>
                </Row1>
            </WithdrawContainer>
            <DepositButton>Preview</DepositButton>

            <WarningContainer>

                <WarningText>
                    For security purposes, large or suspicious withdrawal may take 1-6 hours for audit process. We appreciate your patience!
                </WarningText>
            </WarningContainer>
        </Container>
    );
};

export default SkylaWithdraw;