import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col, Button } from "react-bootstrap";
import RangeCredit from "../../Components/Game/Addons";
import { gameCoin } from "../../../actions/gameCoin";
import { setWallet } from "../../../actions/gameWallet";
import storage from "../../../Storage";
import C from "../../../Constant";
import {
  Event,
  __,
  isValidNumber,
  wait,
  forceSatoshiFormat,
  sendNotfication,
  playAudio,
} from "../../../Helper";
import styled from "styled-components";
import { Info } from "lucide-react";


const Container = styled.div`
  background-color: #323738;
  color: white;
  padding: 20px;
  max-width: 380px;
  border-right: 1px solid #2a2a2a;
  border-bottom: 1px solid #2a2a2a;
  border-radius: 0;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
`;

const Container2 = styled.div`
  background-color: #323738;
  color: white;
  padding: 20px;
  width: 100%;  /* Full width on mobile */
  border-right: 1px solid #2a2a2a;
  border-bottom: 1px solid #2a2a2a;
  border-radius: 0;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;

  /* Desktop size (768px and above) */
  @media (min-width: 768px) {
    max-width: 380px;
  }
`;

const TabContainer = styled.div`
  display: flex;
  margin-bottom: 24px;
  border-bottom: 1px solid #2a2a2a;
`;

const Tab = styled.button`
  padding: 8px 16px;
  color: ${props => props.active ? '#34d399' : '#808080'};
  background: none;
  border: none;
  cursor: pointer;
  position: relative;
  
  &:after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: ${props => props.active ? '#34d399' : 'transparent'};
  }
`;

const NewTag = styled.span`
  color: #fbbf24;
  font-size: 12px;
  margin-left: 4px;
`;

const AmountSection = styled.div`
  margin-bottom: 16px;
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  color: #808080;
  margin-bottom: 4px;
  
  svg {
    margin-left: 4px;
    width: 16px;
    height: 16px;
  }
`;

const BetDisplay = styled.div`
  display: flex;
  align-items: center;
  background-color: #292D2E;
  padding: 4px 12px;
  border-radius: 8px;
  margin-bottom: 16px;
  position:relative;
`;
const SwitcherContainer = styled.div`
display:flex;
  background-color: #292D2E;
  border-radius: 8px;
  padding: 2px;
  gap: 2px;
  margin-top:-14px;
  
`;


const SwitchButton = styled.div`
  border: none;
  padding: 13px 16px;
  border-radius: 8px;
  width:100px;
  font-size: 12px;
  display:flex;
  justify-content:center;
  align-items:center;
  text-warap:no-wrap;
  cursor: pointer;
  transition: all 0.2s ease;
  
  ${props => props.active ? `
    background-color: #3A4142;
    color: white;
  ` : `
    background: none;
    color: #6B7280;
  `}

  &:hover {
    color: ${props => props.active ? 'white' : '#9CA3AF'};
  }
`;
const BetInput = styled.input`
  border: none;
  outline: none;
  color: white;
  background: none;
  
  &:focus {
    background: none;
  }
  
  /* Additional browser resets to ensure no background appears */
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #1a1a1a inset !important;
    -webkit-text-fill-color: white !important;
    transition: background-color 5000s ease-in-out 0s;
  }
`;
const BetInput1 = styled.input`
  border: none;
  outline: none;
  padding: 12px;
  color: white;
  background: none;
  
  &:focus {
    background: none;
  }
  
  /* Additional browser resets to ensure no background appears */
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #1a1a1a inset !important;
    -webkit-text-fill-color: white !important;
    transition: background-color 5000s ease-in-out 0s;
  }
`;


const BetInput2 = styled.input`
  border: none;
  outline: none;
  color: white;
  background: none;
  padding: 12px;
  width: 50%;
  
  &:focus {
    background: none;
  }
  
  /* Additional browser resets to ensure no background appears */
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #1a1a1a inset !important;
    -webkit-text-fill-color: white !important;
    transition: background-color 5000s ease-in-out 0s;
  }
`;


const CurrencyDot = styled.div`
  width: 20px;
  height: 20px;
  background-color: #3b82f6;
  border-radius: 50%;
  margin-right: 8px;
`;

const PercentageText = styled.span`
  color: #808080;
  font-size: 14px;
`;


const MultiplierGroup = styled.div`
  margin-left: auto;
  display: flex;
  gap: 8px;
`;

const MultiplierButton = styled.button`
  background-color: #3A4142;
  padding: 4px 12px;
  border-radius: 6px;
  border: none;
  color: white;
  cursor: pointer;
`;

const ValueGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
  margin-bottom: 24px;
`;

const ValueButton = styled.button`
  background-color: #2a2a2a;
  padding: 4px;
  border-radius: 6px;
  border: none;
  color: white;
  cursor: pointer;
  
  &:hover {
    background-color: #404040;
  }
`;

const WinAmountInput = styled.div`
  background-color: #2a2a2a;
  padding: 12px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

const RollButton = styled.div`
  width: 100%;
  padding: 12px;
  background: linear-gradient(90deg, rgb(36, 238, 137), rgb(159, 232, 113));
  border: none;
  border-radius: 8px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  color:black !important;
  display:flex;
  justify-content:center;
  align-items:center;
`;
const WarningContainer = styled.div`
  background-color: rgba(52, 199, 89, 0.1);
  border-radius: 8px;
  padding: 2px 16px;
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 0 auto;
  margin-bottom: 16px;
    margin-top: 8px;
`;

const DemoText = styled.div`
  color: #808080;
  font-size: 14px;
  text-align: center;
`;



const Container1 = styled.div`
  background-color: #1a1a1a;
  color: white;
  padding: 20px;
  max-width: 380px;
`;

const Section = styled.div`
  margin-bottom: 10px;
`;

const Label1 = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  color: #808080;
  margin-bottom: 10px;
  font-size: 14px;
`;

const AmountDisplay = styled.div`
  display: flex;
  align-items: center;
  background-color: #2a2a2a;
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 12px;
`;

const CurrencyIcon = styled.div`
  width: 24px;
  height: 24px;
  min-width: 24px;
  background-color: #3b82f6;
  border-radius: 50%;
  margin-right: 8px;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 4px;
  margin-left: auto;
`;

const ActionButton = styled.button`
  background-color: #404040;
  padding: 6px 12px;
  border-radius: 6px;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 14px;
  
  &:hover {
    background-color: #505050;
  }
`;

const ChevronGroup = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #404040;
  border-radius: 6px;
  overflow: hidden;

  button {
    border: none;
    background: none;
    color: white;
    padding: 2px;
    cursor: pointer;
    
    &:hover {
      background-color: #505050;
    }
  }
`;

const ValueGrid1 = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
  margin-bottom: 16px;
`;

const ValueButton1 = styled.button`
  background-color: #2a2a2a;
  padding: 8px;
  border-radius: 6px;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 14px;
  
  &:hover {
    background-color: #404040;
  }
`;

const BetControl = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 12px;
`;

const ResetButton = styled.button`
  background-color: #2a2a2a;
  padding: 6px 12px;
  border-radius: 6px;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 14px;
  
  &:hover {
    background-color: #404040;
  }
`;

const InputGroup = styled.div`
  display: flex;
  align-items: center;
  background-color: #2a2a2a;
  border-radius: 6px;
  padding: 6px 12px;
  gap: 8px;
  flex: 1;
`;

const StyledInput = styled.input`
  border: none;
  outline: none;
  background: none;
  color: white;
  width: 60px;
  padding: 4px;
  font-size: 14px;
`;

class Bet extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      engine: this.props.engine,
      bet: "manual",
      started: false,
      isLogged: storage.getKey("logged") !== null ? true : false,
      amount: forceSatoshiFormat(
        storage.getKey("lam") ? storage.getKey("lam") : 0.0000001
      ),
      initialNumber: forceSatoshiFormat(
        storage.getKey("alam") ? storage.getKey("alam") : 0.0000001
      ),
      payout: "2.00",
      inputDisabled: false,
      buttonText: "BET",
      betNumber: 100000,
      firstBetNumber: 100000,
      onWin: 0,
      onLost: 0,
      stopOnWin: 0,
      isWin: false,

      activeTab: 'Manual',
      betAmount: 0,
      multiplier: '1/2',
      amount: 0,
      betsNumber: '∞',
      onWinIncrease: 0,
      onLossIncrease: 0,
      stopOnWin: 0,
      activeButton: 'reset', // or 'increase'
      onWinMode: 'reset', // Add new state for win mode
      onLossMode: 'reset', // Add new state for loss mode
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleBet = this.handleBet.bind(this);
    this.wrapperRef = React.createRef();
    this.payoutRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentWillUnmount() {
    let { engine, started } = this.state;
    engine.init = false;
    this._isMounted = false;

    document.removeEventListener("mousedown", this.handleClickOutside);

    try {
      if (started) document.getElementById("bet").click();
    } catch (e) { }
  }

  componentDidMount() {
    this._isMounted = true;
    let { engine } = this.state;

    document.addEventListener("mousedown", this.handleClickOutside);

    this.props.gameCoin();
    engine.on(C.PLAY_LIMBO, (data) => this.play(data));
    engine.on(C.BUSTED_LIMBO, () => this.busted());
    engine.on(C.ERROR_LIMBO, (data) => this.error(data));
    engine.on("stop_limbo", () => this.stopAuto());
    engine.on("stop_playing", () => this.stopManual());
  }

  stopManual() {
    this.setState({ started: false, buttonText: "BET", inputDisabled: false });
  }

  handleClickOutside = (event) => {
    if (this.payoutRef && this.payoutRef.current) {
      if (!this.payoutRef.current.contains(event.target)) {
        let value = parseFloat(this.state.payout);

        if (isNaN(value) || value < 1.01) {
          value = 1.01;
        }
        if (value > 1000000) {
          value = 1000000;
        }

        this.setState({ payout: parseFloat(value).toFixed(2) });
      }
    }
  }

  stopAuto() {
    this.setState({
      started: false,
      buttonText: "Start Auto Mode",
      inputDisabled: false,
    });
  }

  play(data) {
    if (this._isMounted) {
      let { engine, bet, started } = this.state;

      let isWin = false;
      // this.setState({ isWin: false });

      if (parseFloat(data.profit) >= 0 && parseFloat(data.profit) >= 0.0) {
        isWin = true;
        // this.setState({ isWin: true });
      }

      this.setState({ isWin: isWin });

      this.setCanvas(data.target);

      if (bet === "auto") {
        if (started) {
          wait(500).then(() => {
            engine.isWin = isWin;
            engine.roundProfit =
              __.toNumber(data.profit) + __.toNumber(engine.roundProfit);
            this.placeAutoBet();
          });
        }
      }

      wait(500).then(() => {
        if (isWin) {
          playAudio("win.mp3");
        }
      });
    }
  }

  busted() {
    if (this._isMounted) {
      wait(500).then(() => {
        this.setState({ inputDisabled: false });
      });
    }
  }

  placeAutoBet() {
    if (this._isMounted) {
      let {
        engine,
        amount,
        betNumber,
        firstBetNumber,
        onWin,
        onLost,
        stopOnWin,
        isWin,
        payout,
        initialNumber,
      } = this.state;

      wait(500).then(() => {
        if (onWin != 0) {
          // It Mean Player has win in this round
          if (isWin) {
            var amountChange = amount * (1 + parseFloat(onWin) / 100);
            this.setState({ amount: amountChange });
            console.log("onWin amount:", amountChange);
          }
          else {
            // Reset to initial bet if onWin percentage is 0
            this.setState({ amount: initialNumber });
            console.log(`Win: Reset to initial amount. New amount: ${initialNumber}`);
          }
        }

        if (onLost != 0) {
          // It Mean Player has lost in this round
          if (!isWin) {
            var amountChange = amount * (1 + parseFloat(onLost) / 100);
            this.setState({ amount: amountChange });
            console.log("onLose amount:", amountChange);
          } else if (isWin) {
            console.log(initialNumber, "initial");
            this.setState({ amount: initialNumber });
          }
        }

        amount = this.state.amount;

        engine.init = true;
        engine.amount = parseFloat(amount);
        engine.coin = this.props.coin;
        engine.betNumber = firstBetNumber;
        engine.stopOnWin = parseInt(stopOnWin);
        engine.onWin = parseInt(onWin);
        engine.onLost = parseInt(onLost);
        engine.payout = parseFloat(payout);
        engine.autoStart();
        this.setState({ betNumber: betNumber - 1 });
      });
    }
  }


  placeBet() {
    if (this._isMounted) {
      let { payout, amount, engine } = this.state;

      wait(100).then(() => {
        engine.init = true;
        engine.amount = amount;
        engine.payout = payout;
        engine.coin = this.props.coin;
        engine.start();
      });
    }
  }

  error(data) {
    if (this._isMounted) {
      sendNotfication(data.message, "info", "top-center");

      if (data.code === "credit") this.props.setWallet(true, data.uid);

      this.setState({ inputDisabled: false });

      if (this.state.started) {
        this.setState({ started: false, buttonText: "Start Auto Mode" });
      }
    }
  }

  handleBet() {
    // e.preventDefault();
    let { isLogged, amount, bet, started } = this.state;

    this.setState({ initialNumber: amount });
    storage.setKey("alam", amount);

    // Check User
    if (!isLogged) {
      return Event.emit("showAuthModal", true);
    }

    if (parseFloat(amount) <= 0) {
      this.setState({ amount: forceSatoshiFormat(0.0000001) });
    } else {
      amount = Math.min(
        parseFloat(storage.getKey("credit")),
        parseFloat(amount)
      );
      this.setState({ amount: forceSatoshiFormat(amount) });
    }

    this.setState({ inputDisabled: true });

    // if (bet === "auto") {
      if (this.state.activeTab === "Auto") {
      if (started) {
        this.setState({
          started: false,
          buttonText: "Start Auto Mode",
          inputDisabled: false,
        });
        return false;
      } else {
        this.setState({ started: true, buttonText: "Stop Auto Mode" });
        this.placeAutoBet();
      }
    } else {
      this.placeBet();
    }
  }

  handleInputChange(event) {
    let target = event.target;
    let value = target.value;
    if (
      target.name === "amount" ||
      target.name === "stopOnWin" ||
      target.name === "payout"
    ) {
      this.setState({ amount: value })
      if (!isValidNumber(value)) return;
    }
    if (target.name === "amount") {
      storage.setKey("lam", value);
    }
    this.setState({ [target.name]: value });
  }

  showAnimation(busted) {
    let self = this;
    var counter = 50;
    var interval = setInterval(function () {
      counter--;
      document.querySelector(".rocket-number").innerText =
        counter.toFixed(2) + "x";
      if (counter == 0) {
        document.querySelector(".rocket-number").innerText = busted + "x";
        if (self.state.isWin) {
          document.querySelector(".rocket-number").style.color = "#c8ff4d";
        } else {
          document.querySelector(".rocket-number").style.color = "#ff4949";
        }
        clearInterval(interval);
      }
    }, 1);
  }

  setCanvas(busted) {
    if (this._isMounted) {
      let self = this;

      let SEC = 30,
        LIMIT = 100,
        plused = 0,
        start = 0,
        cnt = 0;

      let progress = setInterval(function () {
        let cnt_str = cnt < 10 ? "0" + cnt : "" + cnt;

        if (cnt_str >= 99) {
          plused = parseInt(start + 1);
          start++;
        }

        if (document.querySelector(".rocket-body") !== null)
          document.querySelector(".rocket-body").style.bottom = cnt * 35 + "px";

        if (cnt >= 13) {
          clearInterval(progress);

          if (document.querySelector(".rocket-number") !== null) {
            self.showAnimation(busted);
          }
          if (document.querySelector(".rocket-body") !== null) {
            document.querySelector(".rocket-body").style.bottom = "0px";
          }
        }

        cnt = (cnt + 1) % LIMIT;
      }, SEC);
    }
  }

  betMode = (type) => {
    let text;

    if (type === "auto") {
      text = "Start Auto Mode";
    } else {
      text = "BET";
    }
    this.setState({ bet: type, buttonText: text });
  };

  setAmount = (val) => {
    var am = forceSatoshiFormat(val);
    this.setState({ amount: am });
    storage.setKey("lam", am);
  };

  setNumber = (e, num) => {
    e.preventDefault();
    this.setState({ betNumber: num, firstBetNumber: num });
  };

  handleTabChange = (tab) => {
    this.setState({ activeTab: tab });
  }

  handleSwitch = (button, type) => {
    if (type === 'win') {
      this.setState({ 
        onWinMode: button,
        // Reset onWin value when switching to reset mode
        onWin: button === 'reset' ? 0 : this.state.onWin 
      });
    } else if (type === 'loss') {
      this.setState({ 
        onLossMode: button,
        // Reset onLost value when switching to reset mode
        onLost: button === 'reset' ? 0 : this.state.onLost 
      });
    }
  };

  render() {
    let { bet, started, inputDisabled } = this.state;
    const { activeButton } = this.state;
    const { activeTab, betAmount } = this.state;
    const tabs = ['Manual', 'Auto', ''];
    return (
      <>


        <Container2>
          {this._isMounted && (

            <div className="w-100 mt-1"
            >
              <>
                <TabContainer>
                  {tabs.map(tab => (
                    <Tab
                      key={tab}
                      active={activeTab === tab}
                      onClick={() => this.handleTabChange(tab)}
                    >
                      {tab}
                      {tab === 'Advanced' && <NewTag>New!</NewTag>}
                    </Tab>
                  ))}
                </TabContainer>
                {
                  activeTab == "Manual" &&
                  <>

                    <AmountSection>
                      <Label>
                        Amount
                        <Info />
                      </Label>

                      <BetDisplay>
                        <img
                          src={"/assets/images/" + this.props.coin + ".png"}
                          className={"mini-coin-7"}
                          alt=""
                        />



                        <BetInput type="text"
                          disabled={inputDisabled}
                          className="form-control"
                          id="amount"
                          name="amount"
                          value={this.state.amount}
                          autoComplete={"off"}
                          onKeyUp={this.handleInputChange}
                          onChange={this.handleInputChange} />
                        <MultiplierGroup>
                          <MultiplierButton>1/2</MultiplierButton>
                          <MultiplierButton>2x</MultiplierButton>
                          <div className="input-group-append">
                            <RangeCredit set={this.setAmount} />
                          </div>
                        </MultiplierGroup>
                      </BetDisplay>
                    </AmountSection>

                    <Label>Payout</Label>
                    {/* <WinAmountInput>
                    <img
                      src={"/assets/images/" + this.props.coin + ".png"}
                      className={"mini-coin-7"}
                      alt=""
                    />
                    <span style={{ marginLeft: "15px" }}>{forceSatoshiFormat(
                      this.props.classic_dice_payout
                        ? this.state.amount * this.props.classic_dice_payout
                        : 0.00000004
                    )}</span>
                  </WinAmountInput> */}

                    <BetDisplay>
                      <BetInput1 
                           ref={this.payoutRef}
                           disabled={inputDisabled}
                           type="text"
                           
                           id="payout"
                           name="payout"
                           placeholder=".."
                           value={this.state.payout}
                           autoComplete={"off"}
                           onChange={this.handleInputChange} />
                    </BetDisplay>

                    <RollButton onClick={()=>{
                 
                       if (this.props.coin === 'INR') {
                         if (this.state.amount < 50) {
                           sendNotfication('Enter valid amount')
           
                         } else {
                           this.handleBet();
                         }
                       }
                       else if (this.props.coin === 'USDT') {
                         if (this.state.amount < 1) {
                           sendNotfication('Enter valid amount')
           
                         } else {
                           this.handleBet();
           
                         }
                       } else {
                         this.handleBet();
           
                       }
                     }}
                  >Bet</RollButton>
                    <WarningContainer>
                      <DemoText>Betting with $0 will enter demo mode.</DemoText>
                    </WarningContainer>
                  </>
                }

                {

                  activeTab == "Auto" && (
                    <>

                      <Section>
                        <Label>
                          Amount <Info size={16} />
                        </Label>

                        <BetDisplay>
                          <img
                            src={"/assets/images/" + this.props.coin + ".png"}
                            className={"mini-coin-7"}
                            alt=""
                          />



                          <BetInput type="text"
                            disabled={inputDisabled}
                            className="form-control"
                            id="amount"
                            name="amount"
                            value={this.state.amount}
                            autoComplete={"off"}
                            onKeyUp={this.handleInputChange}
                            onChange={this.handleInputChange} />
                          <MultiplierGroup>
                            <MultiplierButton>1/2</MultiplierButton>
                            <MultiplierButton>2x</MultiplierButton>
                            <div className="input-group-append">
                              <RangeCredit set={this.setAmount} />
                            </div>
                          </MultiplierGroup>
                        </BetDisplay>
                      </Section>

                      <Label>Number of Bets</Label>
                      <BetDisplay>




                        <BetInput
                          type="number"
                          disabled={inputDisabled}
                          className="form-control"
                          required={true}
                          value={this.state.betNumber}
                          onChange={(e) => this.setNumber(e, e.target.value)} />
                        <MultiplierGroup>
                          <MultiplierButton onClick={(e) => this.setNumber(e, 10)}>10</MultiplierButton>
                          <MultiplierButton onClick={(e) => this.setNumber(e, 50)}>50</MultiplierButton>
                          <MultiplierButton onClick={(e) => this.setNumber(e, 100)}>100</MultiplierButton>

                        </MultiplierGroup>
                      </BetDisplay>

                      <Section>
                        <Label>On win</Label>
                        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                          <SwitcherContainer>
                            <SwitchButton
                              active={this.state.onWinMode === 'reset'}
                              onClick={() => this.handleSwitch('reset', 'win')}
                            >
                              Reset
                            </SwitchButton>
                            <SwitchButton
                              active={this.state.onWinMode === 'increase'}
                              onClick={() => this.handleSwitch('increase', 'win')}
                            >
                              Increase by
                            </SwitchButton>
                          </SwitcherContainer>

                          <BetDisplay>



                            <BetInput2 
                              disabled={inputDisabled || this.state.onWinMode === 'reset'}
                             
                              name="onWin"
                              min="0"
                              autoComplete={"off"}
                              value={this.state.onWin}
                              onChange={(e) => this.setState({ onWin: e.target.value })}
                            />
                            <PercentageText>%</PercentageText>

                          </BetDisplay>
                        </div>
                      </Section>

                      <Section>
                        <Label>On Loss</Label>
                        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                          <SwitcherContainer>
                            <SwitchButton
                              active={this.state.onLossMode === 'reset'}
                              onClick={() => this.handleSwitch('reset', 'loss')}
                            >
                              Reset
                            </SwitchButton>
                            <SwitchButton
                              active={this.state.onLossMode === 'increase'}
                              onClick={() => this.handleSwitch('increase', 'loss')}
                            >
                              Increase by
                            </SwitchButton>
                          </SwitcherContainer>

                          <BetDisplay>



                            <BetInput2
                              disabled={inputDisabled || this.state.onLossMode === 'reset'}
                           
                              name="onLost"
                              min="0"
                              autoComplete={"off"}
                              value={this.state.onLost}
                              onChange={(e) => this.setState({ onLost: e.target.value })}
                            />
                            <PercentageText>%</PercentageText>

                          </BetDisplay>
                        </div>
                      </Section>

                      <Section>
                        <Label>Stop On Win</Label>
                        <BetDisplay>
                          <img
                            src={"/assets/images/" + this.props.coin + ".png"}
                            className={"mini-coin-7"}
                            alt=""
                          />



                          <BetInput type="text"
                            disabled={inputDisabled}

                            className="form-control"
                            name="stopOnWin"
                            placeholder=".."
                            value={this.state.stopOnWin}
                            autoComplete={"off"}
                            onChange={this.handleInputChange} />

                        </BetDisplay>
                      </Section>
                      <RollButton onClick={() => {
                        {

                          if (this.props.coin === 'INR') {
                            if (this.state.amount < 50) {
                              sendNotfication('Enter valid amount')

                            } else {
                              this.handleBet();
                            }
                          }
                          else if (this.props.coin === 'USDT') {
                            if (this.state.amount < 1) {
                              sendNotfication('Enter valid amount')

                            } else {
                              this.handleBet();

                            }
                          } else {
                            this.handleBet();

                          }
                        }

                      }}>Start Auto Bet</RollButton>

                      <WarningContainer>
                        <DemoText>Betting with $0 will enter demo mode.</DemoText>
                      </WarningContainer>
                    </>
                  )
                }

              </>

              {/* <>
              <div
              
              >
                <Row>
                  <Col sm={12} className={"d-md-none d-sm-block"}>
                    {bet === "manual" && (
                      <Button
                        variant={
                          "btn btn-xs btn-block btn-bet py-2 mb-2 " +
                          this.state.buttonType
                        }
                        disabled={inputDisabled}
                        type="submit"
                      >
                        {this.state.buttonText}
                      </Button>
                    )}
                  </Col>
                  <Col xl={6} md={7}>
                    <div className={"form-group mb-1 bet-input"}>
                      <div className="input-group">
                        <div className="input-group-append">
                          <span className="input-group-text">
                            <img
                              src={"/assets/images/" + this.props.coin + ".png"}
                              className={"mini-coin-7"}
                              alt=""
                            />
                            BET
                          </span>
                        </div>
                        <input
                          type="text"
                          disabled={inputDisabled}
                          className="form-control"
                          id="amount"
                          name="amount"
                          value={this.state.amount}
                          autoComplete={"off"}
                          onKeyUp={this.handleInputChange}
                          onChange={this.handleInputChange}
                        />
                        <div className="input-group-append">
                          <RangeCredit set={this.setAmount} />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xl={6} md={5}>
                    <div className={"form-group mb-1 bet-input"}>
                      <div className="input-group">
                        <div className="input-group-append">
                          <span className="input-group-text">
                            <img
                              src={"/assets/images/" + this.props.coin + ".png"}
                              className={"mini-coin-7"}
                              alt=""
                            />
                            WIN AMOUNT
                          </span>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          className="form-control"
                          value={forceSatoshiFormat(
                            this.props.classic_dice_payout
                              ? this.state.amount * this.props.classic_dice_payout
                              : 0.00000004
                          )}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <div>
                  <span style={{ fontSize: 11, marginLeft: 5 }}>
                    {this.props.coin === 'INR' ?
                      "(Minimum Bet - 50 INR)"
                      :
                      "(Minimum Bet - 0.0001  USDT)"
                    }
                  </span>
                </div>

                {bet === "manual" && (
                  <Row>
                    <Col md={5} sm={12} className="m-auto hidden-sm">
                      <div className={"form-group rev mb-1"}>
                        <Button
                          variant={
                            "btn btn-md btn-block mt-2 btn-bet " +
                            this.state.buttonType
                          }
                          disabled={inputDisabled}
                          type="submit"
                        >
                          {this.state.buttonText}

                        </Button>
                      </div>
                    </Col>
                  </Row>
                )}

                {bet === "auto" && (
                  <>
                    <Row>
                      <Col xl={6} md={6} sm={6}>
                        <div className={"form-group mb-1 bet-input"}>
                          <div className="input-group">
                            <div className="input-group-append">
                              <span className="input-group-text">Bets Num</span>
                            </div>
                            <input
                              type="number"
                              disabled={inputDisabled}
                              className="form-control"
                              required={true}
                              value={this.state.betNumber}
                              onChange={(e) => this.setNumber(e, e.target.value)}
                            />
                            <div className="input-group-append">
                              <button
                                onClick={(e) => this.setNumber(e, 10)}
                                className={"btn btn-s-4 btn-xs"}
                              >
                                10
                              </button>
                              <button
                                onClick={(e) => this.setNumber(e, 50)}
                                className={"btn btn-s-4 btn-xs"}
                              >
                                50
                              </button>
                              <button
                                onClick={(e) => this.setNumber(e, 100)}
                                className={"btn btn-s-4 btn-xs"}
                              >
                                100
                              </button>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col xl={6} md={6} sm={12}>
                        <div className={"form-group mb-1 bet-input"}>
                          <div className="input-group">
                            <div className="input-group-append">
                              <span className="input-group-text">
                                Increase Amount On Win %
                              </span>
                            </div>
                            <input
                              disabled={inputDisabled}
                              type="number"
                              className="form-control"
                              name="onWin"
                              min="0"
                              autoComplete={"off"}
                              value={this.state.onWin}
                              onChange={(e) =>
                                this.setState({ onWin: e.target.value })
                              }
                            />
                            <div className="input-group-append">
                              <button
                                className="btn bg-cs2 after-text no-hover npt nh"
                                type="button"
                              >
                                X
                              </button>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col xl={6} md={6} sm={12}>
                        <div className={"form-group mb-1 bet-input"}>
                          <div className="input-group">
                            <div className="input-group-append">
                              <span className="input-group-text">
                                Increase Amount On Lost %
                              </span>
                            </div>
                            <input
                              disabled={inputDisabled}
                              type="number"
                              className="form-control"
                              name="onLost"
                              min="0"
                              autoComplete={"off"}
                              value={this.state.onLost}
                              onChange={(e) =>
                                this.setState({ onLost: e.target.value })
                              }
                            />
                            <div className="input-group-append">
                              <button
                                className="btn bg-cs2 after-text no-hover npt nh"
                                type="button"
                              >
                                X
                              </button>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col xl={6} md={6} sm={12}>
                        <div className={"form-group mb-1 bet-input"}>
                          <div className="input-group">
                            <div className="input-group-append">
                              <span className="input-group-text">
                                Stop On Win
                              </span>
                            </div>
                            <input
                              disabled={inputDisabled}
                              type="text"
                              className="form-control"
                              name="stopOnWin"
                              placeholder=".."
                              value={this.state.stopOnWin}
                              autoComplete={"off"}
                              onChange={this.handleInputChange}
                            />
                            <div className="input-group-append">
                              <div className="input-group-text">
                                <img
                                  src={
                                    "/assets/images/" + this.props.coin + ".png"
                                  }
                                  className={"mini-coin-2 mr-1"}
                                  alt=""
                                />
                                {this.props.coin}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={5} className={"m-auto"}>
                        <Button
                          variant={"btn btn-md btn-block mt-2 btn-bet"}
                          type="submit"
                        >
                          {this.state.buttonText}
                          {started && (
                            <>
                              <div
                                className={
                                  "ml-2 spinner-border spinner-border-sm"
                                }
                              />
                            </>
                          )}
                        </Button>
                      </Col>
                    </Row>
                  </>
                )}
              </div>
              <div
                className="btn-group btn-group-toggle my-2 ml-1"
                data-toggle="buttons"
              >
                <label
                  className={
                    "" + bet === "manual"
                      ? "btn bg-cs2 btn-xs active"
                      : "btn bg-cs2 btn-xs "
                  }
                >
                  <input
                    disabled={started}
                    onClick={(e) => this.betMode("manual")}
                    type="radio"
                  />{" "}
                  Manual
                </label>
                <label
                  className={
                    "" + bet === "auto"
                      ? "btn bg-cs2 btn-xs active"
                      : "btn bg-cs2 btn-xs "
                  }
                >
                  <input
                    disabled={started}
                    onClick={(e) => this.betMode("auto")}
                    type="radio"
                  />{" "}
                  Auto
                </label>
              </div>
            </> */}
            </div>



          )}
        </Container2>
        {/* <form
          className="w-100"
          onSubmit={(e) => {
            e.preventDefault();
            if (this.props.coin === 'INR') {
              if (this.state.amount < 50) {
                sendNotfication('Enter valid amount')

              } else {
                this.handleBet(e);
              }
            }
            else if (this.props.coin === 'USDT') {
              if (this.state.amount < 1) {
                sendNotfication('Enter valid amount')

              } else {
                this.handleBet(e);

              }
            } else {
              this.handleBet(e);

            }
          }}
        >
          <Row>
            <Col xl={6} md={8} sm={12}>
              <div className={"form-group mb-1 bet-input"}>
                <div className="input-group">
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <img
                        src={"/assets/images/" + this.props.coin + ".png"}
                        className={"mini-coin-7"}
                        alt=""
                      />
                      BET
                    </span>
                  </div>
                  <input
                    disabled={inputDisabled}
                    type="text"
                    className="form-control text-left"
                    id="amount"
                    name="amount"
                    placeholder="Enter Bet Amount"
                    value={this.state.amount}
                    autoComplete={"off"}
                    onKeyUp={this.handleInputChange}
                    onChange={this.handleInputChange}
                  />
                  <div className="input-group-append">
                    <RangeCredit set={this.setAmount} />
                  </div>
                </div>
              </div>
            </Col>
            <Col xl={6} md={4} sm={12}>
              <div className={"form-group mb-1 bet-input"}>
                <div className="input-group">
                  <div className="input-group-append">
                    <span className="input-group-text">PAYOUT</span>
                  </div>
                  <input
                    ref={this.payoutRef}
                    disabled={inputDisabled}
                    type="text"
                    className="form-control text-left"
                    id="payout"
                    name="payout"
                    placeholder=".."
                    value={this.state.payout}
                    autoComplete={"off"}
                    onChange={this.handleInputChange}
                  />
                  <div className="input-group-append">
                    <button
                      className="btn bg-cs2 after-text no-hover npt nh"
                      type="button"
                    >
                      <i className="mdi mdi-close" />
                    </button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <div>
            <span style={{ fontSize: 11, marginLeft: 5 }}>
              {this.props.coin === 'INR' ?
                "(Minimum Bet - 50 INR)"
                :
                "(Minimum Bet - 0.0001  USDT)"
              }
            </span>
          </div>
          <Row>
            <Col md={5} sm={12} className="m-auto">
              {bet === "manual" && (
                <Button
                  variant={"btn btn-md btn-block my-1 btn-bet"}
                  disabled={inputDisabled}
                  type="submit"
                >
                  {this.state.buttonText}
                </Button>
              )}
            </Col>
          </Row>
          {bet === "auto" && (
            <>
              <Row>
                <Col xl={6} md={6} sm={12}>
                  <div className={"form-group mb-1 bet-input"}>
                    <div className="input-group">
                      <div className="input-group-append">
                        <span className="input-group-text">Bets Num</span>
                      </div>
                      <input
                        type="number"
                        disabled={inputDisabled}
                        className="form-control"
                        required={true}
                        value={this.state.betNumber}
                        onChange={(e) => this.setNumber(e, e.target.value)}
                      />
                      <div className="input-group-append">
                        <button
                          onClick={(e) => this.setNumber(e, 10)}
                          className={"btn btn-s-4 btn-xs"}
                        >
                          10
                        </button>
                        <button
                          onClick={(e) => this.setNumber(e, 50)}
                          className={"btn btn-s-4 btn-xs"}
                        >
                          50
                        </button>
                        <button
                          onClick={(e) => this.setNumber(e, 100)}
                          className={"btn btn-s-4 btn-xs"}
                        >
                          100
                        </button>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xl={6} md={6} sm={12}>
                  <div className={"form-group mb-1 bet-input"}>
                    <div className="input-group">
                      <div className="input-group-append">
                        <span className="input-group-text">
                          Increase Amount On Win %
                        </span>
                      </div>
                      <input
                        disabled={inputDisabled}
                        type="number"
                        className="form-control"
                        name="onWin"
                        min="0"
                        autoComplete={"off"}
                        value={this.state.onWin}
                        onChange={(e) =>
                          this.setState({ onWin: e.target.value })
                        }
                      />
                      <div className="input-group-append">
                        <button
                          className="btn bg-cs2 after-text no-hover npt nh"
                          type="button"
                        >
                          X
                        </button>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xl={6} md={6} sm={12}>
                  <div className={"form-group mb-1 bet-input"}>
                    <div className="input-group">
                      <div className="input-group-append">
                        <span className="input-group-text">
                          Increase Amount On Lost %
                        </span>
                      </div>
                      <input
                        disabled={inputDisabled}
                        type="number"
                        className="form-control"
                        name="onLost"
                        min="0"
                        autoComplete={"off"}
                        value={this.state.onLost}
                        onChange={(e) =>
                          this.setState({ onLost: e.target.value })
                        }
                      />
                      <div className="input-group-append">
                        <button
                          className="btn bg-cs2 after-text no-hover npt nh"
                          type="button"
                        >
                          X
                        </button>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xl={6} md={6} sm={12}>
                  <div className={"form-group mb-1 bet-input"}>
                    <div className="input-group">
                      <div className="input-group-append">
                        <span className="input-group-text">Stop On Win</span>
                      </div>
                      <input
                        disabled={inputDisabled}
                        type="text"
                        className="form-control"
                        name="stopOnWin"
                        placeholder=".."
                        value={this.state.stopOnWin}
                        autoComplete={"off"}
                        onChange={this.handleInputChange}
                      />
                      <div className="input-group-append">
                        <div className="input-group-text">
                          <img
                            src={"/assets/images/" + this.props.coin + ".png"}
                            className={"mini-coin-2 mr-1"}
                            alt=""
                          />
                          {this.props.coin}
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={5} className={"m-auto"}>
                  <div className={"form-group rev mb-1 mt-2"}>
                    <Button
                      variant={"btn btn-md btn-block btn-bet"}
                      type="submit"
                    >
                      {this.state.buttonText}
                      {started && (
                        <>
                          <div
                            className={"ml-2 spinner-border spinner-border-sm"}
                          />
                        </>
                      )}
                    </Button>
                  </div>
                </Col>
              </Row>
            </>
          )}
        </form>
        <div
          className="btn-group btn-group-toggle mb-1 ml-1"
          data-toggle="buttons"
        >
          <label
            className={
              "" + bet === "manual"
                ? "btn bg-cs2 btn-xs active"
                : "btn bg-cs2 btn-xs "
            }
          >
            <input
              disabled={started}
              onClick={(e) => this.betMode("manual")}
              type="radio"
            />{" "}
            Manual
          </label>
          <label
            className={
              "" + bet === "auto"
                ? "btn bg-cs2 btn-xs active"
                : "btn bg-cs2 btn-xs "
            }
          >
            <input
              disabled={started}
              onClick={(e) => this.betMode("auto")}
              type="radio"
            />{" "}
            Auto
          </label>
        </div> */}
      </>
    );
  }
}

Bet.propTypes = {
  coin: PropTypes.string,
  credit: PropTypes.string,
};

const mapStateToProps = (state) => ({
  coin: state.items.coin,
  credit: state.items.credit,
});

export default connect(mapStateToProps, { gameCoin, setWallet })(Bet);

