// import React, { useState } from 'react';
// import styled from 'styled-components';
// import { Settings, X, Camera } from 'lucide-react';

// const Container = styled.div`
//   background-color: #1a1d24;
//   color: #e5e7eb;
//   min-height: 100vh;
// `;

// const ContentWrapper = styled.div`
//   display: flex;
//   width: 100%;
// `;

// const MatchesSection = styled.div`
//   width: 70%;
//   padding: 16px;
//   background-color: #1a1d24;
// `;

// const BetslipSection = styled.div`
//   width: 30%;
//   background-color: #22252c;
//   border-left: 1px solid #2a2e35;
//   min-width: 300px;
// `;

// const EmptyBetSlip = styled.div`
//   padding: 24px;
//   text-align: center;
//   color: #9ca3af;
//   font-size: 12px;
// `;

// const Header = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   padding: 8px 16px;
//   background-color: #374151;
// `;

// const Tabs = styled.div`
//   display: flex;
// `;

// const TabButton = styled.button`
//   padding: 8px 16px;
//   border-radius: 8px 8px 0 0;
//   background-color: ${props => props.active ? '#1f2937' : 'transparent'};
//   color: ${props => props.active ? '#fff' : '#e5e7eb'};
//   outline: none;
//   cursor: pointer;
//   border: none;
//   font-size: 14px;
// `;

// const BetSlip = styled.div`
//   width: 288px;
//   background-color: #374151;
//   padding: 16px;
//   border-radius: 8px;
// `;

// const BetSlipHeader = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   margin-bottom: 16px;
//   font-size: 14px;
// `;

// const BetSlipItem = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   margin-bottom: 8px;
//   font-size: 14px;
// `;

// const BetSlipInput = styled.input`
//   background-color: #4b5563;
//   padding: 4px 8px;
//   border-radius: 4px;
//   width: 96px;
//   border: none;
//   font-size: 14px;
//   color: #e5e7eb;
// `;

// const BetSlipButton = styled.button`
//   background-color: #22c55e;
//   color: #fff;
//   padding: 8px 16px;
//   border-radius: 4px;
//   width: 100%;
//   border: none;
//   font-size: 14px;
//   cursor: pointer;
// `;

// const SportsContainer = styled.div`
//   padding: 16px;
//   display: grid;
//   grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
//   gap: 16px;
// `;

// const SportItem = styled.div`
//   background-color: #374151;
//   padding: 16px;
//   border-radius: 8px;
// `;

// const SportItemHeader = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   margin-bottom: 8px;
//   font-size: 14px;
// `;

// const SportContent = styled.div`
//   display: grid;
//   grid-template-columns: repeat(3, 1fr);
//   gap: 8px;
// `;

// const SportContentItem = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   padding: 8px;
//   border-radius: 4px;
//   background-color: ${props => props.active ? '#22c55e' : '#4b5563'};
//   color: ${props => props.active ? '#fff' : '#e5e7eb'};
//   cursor: pointer;
//   font-size: 14px;
// `;

// const SportContentItemLabel = styled.span`
//   margin-top: 4px;
//   font-size: 12px;
// `;

// const CloseButton = styled.button`
//   background: none;
//   border: none;
//   cursor: pointer;
// `;

// const SportsApp = () => {
//   const [activeTab, setActiveTab] = useState('In-Play');
//   const [selectedBet, setSelectedBet] = useState(null);

//   const handleTabClick = (tab) => {
//     setActiveTab(tab);
//   };

//   const handleBetSelect = (bet) => {
//     setSelectedBet(bet);
//   };

//   return (
//     <Container>
//       <Header>
//         <Tabs>
//           {['In-Play', 'Cricket', 'Soccer', 'Tennis'].map(tab => (
//             <TabButton 
//               key={tab}
//               active={activeTab === tab} 
//               onClick={() => handleTabClick(tab)}
//             >
//               {tab}
//             </TabButton>
//           ))}
//         </Tabs>
//         <Settings size={16} />
//       </Header>

//       <ContentWrapper>
//         <MatchesSection>
//           <SportsContainer>
//             <SportItem>
//               <SportItemHeader>
//                 <span>Carolina Eagles v New York Cowboys</span>
//                 <Camera size={14} />
//               </SportItemHeader>
//               <SportContent>
//                 <SportContentItem active={true} onClick={() => handleBetSelect('Carolina Eagles')}>
//                   <span>1</span>
//                   <SportContentItemLabel>IR4.44M</SportContentItemLabel>
//                 </SportContentItem>
//                 <SportContentItem onClick={() => handleBetSelect('X')}>
//                   <span>X</span>
//                   <SportContentItemLabel>--</SportContentItemLabel>
//                 </SportContentItem>
//                 <SportContentItem onClick={() => handleBetSelect('New York Cowboys')}>
//                   <span>2</span>
//                   <SportContentItemLabel>--</SportContentItemLabel>
//                 </SportContentItem>
//               </SportContent>
//             </SportItem>
//             {/* Add more sport items here */}
//           </SportsContainer>
//         </MatchesSection>

//         <BetslipSection>
//           {selectedBet ? (
//             <>
//               <BetSlipHeader>
//                 <span>Bet Slip</span>
//                 <CloseButton onClick={() => setSelectedBet(null)}>
//                   <X size={14} />
//                 </CloseButton>
//               </BetSlipHeader>
//               <BetSlipContent>
//                 <BetSlipItem>
//                   <span>{selectedBet}</span>
//                   <span>6.8</span>
//                 </BetSlipItem>
//                 <BetSlipItem>
//                   <span>Stake</span>
//                   <BetSlipInput type="number" placeholder="0.00" />
//                 </BetSlipItem>
//                 <BetSlipItem>
//                   <span>Potential Win</span>
//                   <span>2,900.00</span>
//                 </BetSlipItem>
//                 <BetSlipButton>Place Bet</BetSlipButton>
//               </BetSlipContent>
//             </>
//           ) : (
//             <EmptyBetSlip>
//               <p>Your bet slip is empty</p>
//               <small>Click on any odds to add a selection</small>
//             </EmptyBetSlip>
//           )}
//         </BetslipSection>
//       </ContentWrapper>
//     </Container>
//   );
// };

// export default SportsApp;
import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const gradient = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

const float = keyframes`
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
`;

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: ${gradient} 15s ease infinite;

  text-align: center;
  color: white;
`;

const Title = styled.h1`
  font-size: 3.5rem;
  margin-bottom: 1rem;
  animation: ${fadeIn} 1s ease-out, ${float} 6s ease-in-out infinite;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);

  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
`;

const Subtitle = styled.h2`
  font-size: 1.8rem;
  margin-bottom: 2rem;
  opacity: 0;
  animation: ${fadeIn} 1s ease-out 0.5s forwards;
  font-weight: 300;

  @media (max-width: 768px) {
    font-size: 1.3rem;
  }
`;

const Text = styled.p`
  font-size: 1.2rem;
  max-width: 600px;
  line-height: 1.6;
  opacity: 0;
  animation: ${fadeIn} 1s ease-out 1s forwards;

  @media (max-width: 768px) {
    font-size: 1rem;
    padding: 0 20px;
  }
`;

const CountdownContainer = styled.div`
  display: flex;
  gap: 20px;
  margin: 2rem 0;
  opacity: 0;
  animation: ${fadeIn} 1s ease-out 1.5s forwards;

  @media (max-width: 768px) {
    gap: 10px;
  }
`;

const CountdownItem = styled.div`
  background: rgba(255, 255, 255, 0.1);
  padding: 15px;
  border-radius: 10px;
  min-width: 80px;
  backdrop-filter: blur(10px);

  @media (max-width: 768px) {
    min-width: 60px;
    padding: 10px;
  }
`;

const Number = styled.div`
  font-size: 2rem;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const Label = styled.div`
  font-size: 0.9rem;
  opacity: 0.8;

  @media (max-width: 768px) {
    font-size: 0.7rem;
  }
`;

const SportsApp = () => {
  const [timeLeft, setTimeLeft] = useState({
    days: 30,
    hours: 0,
    minutes: 0,
    seconds: 0
  });

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(prev => ({
        ...prev,
        seconds: prev.seconds > 0 ? prev.seconds - 1 : 59,
        minutes: prev.seconds === 0 ? (prev.minutes > 0 ? prev.minutes - 1 : 59) : prev.minutes,
        hours: prev.minutes === 0 && prev.seconds === 0 ? (prev.hours > 0 ? prev.hours - 1 : 23) : prev.hours,
        days: prev.hours === 0 && prev.minutes === 0 && prev.seconds === 0 ? prev.days - 1 : prev.days
      }));
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <Container>
      <Title>Coming Soon</Title>
      <Subtitle>Something Amazing is Coming</Subtitle>
      {/* <CountdownContainer>
        <CountdownItem>
          <Number>{timeLeft.days}</Number>
          <Label>Days</Label>
        </CountdownItem>
        <CountdownItem>
          <Number>{timeLeft.hours}</Number>
          <Label>Hours</Label>
        </CountdownItem>
        <CountdownItem>
          <Number>{timeLeft.minutes}</Number>
          <Label>Minutes</Label>
        </CountdownItem>
        <CountdownItem>
          <Number>{timeLeft.seconds}</Number>
          <Label>Seconds</Label>
        </CountdownItem>
      </CountdownContainer> */}
      <Text>
        We're working hard to bring you something extraordinary. Stay tuned for the big reveal!
      </Text>
    </Container>
  );
};

export default SportsApp;