import React, { useState, useEffect, useLayoutEffect, useCallback } from "react";
import { Link, useHistory } from 'react-router-dom';
import styled from "styled-components";
import Login from "../../Auth/Login";
import Register from "../../Auth/Register";
import SkylaMenu from "../../../../Icons/Menu";
import MenuPlayIcon from "../../../../Icons/Menu";
import SearchStarIcon from "../../../../Icons/Search";
import MessageIcon from "../../../../Icons/Chat";
import GlobeIcon from "../../../../Icons/Glob";
import SkylaSidebar from "../../../Components/SkylaSiebar";
import { useSidebar } from "../../../../context/SidebarContext";
import User from "../../Parts/User/index.js"
import SkylaProfile from "../SkylaHeaderComponents/SkylaUserProfile.js";
import ProfileMenu from "../SkylaHeaderComponents/SkylaUserProfile.js";
import Credit from './../Credit';
import SkylaCredit from "../SkylaCredit/index.js";
import NotificationsSkyla from "../../../../Icons/Notifications.js";
import GiftSkyla from "../../../../Icons/GiftSkyla.js";
import BottomNavigation from "../../../Components/SkylaBottomTab/index.js";
import { addNewStyle, CRISTP_ID, wait } from "../../../../Helper.js";

const HeaderWrapper = styled.div`
  width: 100%;
  background-color: #292D2E;
  border-bottom: 1px solid #2C2F36;
  height: 63px;
  padding: 12px 16px;
`;

const HeaderContainer = styled.header`

  display: flex;
  align-items: center;
  justify-content: space-between;
  // padding: 0 16px;
`;

const LeftSection = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const LogoContainer = styled(Link)`
  display: flex;
  align-items: center;
`;

const Logo = styled.img`
  width: ${props => props.showFull ? '128px' : '96px'};
  height: auto;
  transition: width 0.3s ease;
`;

const Logo1 = styled.img`
  width: 40px;
  height: auto;
  transition: width 0.3s ease;
`;


const SearchBarContainer = styled.div`
  display: none;
  flex: 1;
  max-width: 576px;
  margin: 0 16px;
  
  @media (min-width: 768px) {
    display: flex;
  }
`;

const SearchBarWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const SearchInputContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: #1B1E22;
  border-radius: 9999px;
  padding: 8px 16px;
  transition: all 0.2s ease;

  &:focus-within {
    border: 1px solid #4CAF50;
  }
`;

const SearchIcon = styled.img`
  width: 16px;
  height: 16px;
  opacity: 0.6;
`;

const SearchInput = styled.input`
  width: 100%;
  background: transparent;
  border: none;
  color: white;
  font-size: 14px;
  margin-left: 8px;
  outline: none;

  &::placeholder {
    color: #9CA3AF;
  }
`;

const RightSection = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const IconButton = styled.button`
  padding: 8px;
  background: transparent;
  border: none;
  border-radius: 9999px;
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #2C2F36;
  }
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;
`;

const AuthButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const SignInButton = styled.button`
  padding: 6px 16px;
  background: transparent;
  color: white;
  font-size: 14px;
  font-weight: 500;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #2C2F36;
  }
`;

const SignUpButton = styled.button`
  padding: 6px 16px;
  background-color: #10B981;
  color: white;
  font-size: 14px;
  font-weight: 500;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #059669;
  }
`;

const IconBg = styled.div`
  padding: 6px 8px;
  background-color: #394142;
  border-radius:10px;
 
`;

const IconBg2 = styled.div`
 display:none;

    @media (max-width: 768px) {
   display:block;
     padding: 6px 8px;
  background-color: #394142;
  border-radius:10px;
  }
 
`;
const IconBg1 = styled.div`
  padding: 6px 8px;
  background-color: #394142;
  border-radius:10px;
  display:flex;
  align-items:center;
  gap:4px;
 
`;

const ProfileButton = styled(Link)`
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 8px;
`;

const ProfileImage = styled.img`
  height: 30px;
  width: 30px;
  border-radius: 50%;
  object-fit: contain;
`;


const ResponsiveSidebarWrapper = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;


const Header = () => {
  const [show, setShow] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [showLogo, setShowLogo] = useState(window.innerWidth > 768);
  const { isCollapsed, setIsCollapsed } = useSidebar();
  const [loaded,setLoaded] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const currentWidth = window.innerWidth;
      setIsMobile(currentWidth <= 768);
      setShowLogo(currentWidth > 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleCollapse = useCallback(() => {
    setIsCollapsed(prev => !prev);
  }, [setIsCollapsed]);

  const support = () => {
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = CRISTP_ID;

    if (!loaded) {
        console.log("support");
        setLoaded(true);
        const script = document.createElement('script');
        script.src = "https://client.crisp.chat/l.js";
        script.id = "chtt";
        script.async = true;
        document.head.appendChild(script);
    }

    addNewStyle("#crisp-chatbox {display:block !important;}");
    addNewStyle(
        ".crisp-client .cc-kv6t .cc-1xry .cc-ge4v .cc-qqzz .cc-5b24 .cc-19cg {display:none !important;}"
    );

    wait(2000).then(() => {
        const close = document.querySelector(".cc-wdhl");
        if (close === null) return;
        close.addEventListener("click", function (event) {
            addNewStyle("#crisp-chatbox {display:none !important;}");
            document.getElementById("chtt").src = "";
        });
    });
};
const history=useHistory();
  return (
    <div style={{ display: "flex" }}>
      <ResponsiveSidebarWrapper>
        <SkylaSidebar isCollapsed={isCollapsed} />
      </ResponsiveSidebarWrapper>


      <HeaderWrapper>
        <HeaderContainer>
          <LeftSection>
           {
            !isMobile &&( <IconBg2 onClick={handleCollapse}>
              <MenuPlayIcon />
            </IconBg2>)
           }
           {
            isMobile?(
              <LogoContainer to="/">
            
                 <Logo1
                src="/assets/images/favicon.png"
                alt="Logo"
                showFull={showLogo}
              />
            </LogoContainer>
            ):(
              <LogoContainer to="/">
              <Logo
                src="/assets/images/lg.png"
                alt="Logo"
                showFull={showLogo}
              />
            </LogoContainer>
            )
           }

           {/* {
            isMobile&&(<div>SG</div>)
           } */}
          </LeftSection>
          <RightSection>
           {
            !isMobile &&(
              <IconBg>
              <SearchStarIcon />
            </IconBg>
            )
           }
            {/* <AuthButtons>
              <Login />
              <Register />
            </AuthButtons> */}
            
            {/* <User /> */}
            {/* <Credit/> */}
            <SkylaCredit/>
          
            

            <IconBg onClick={()=>history.push("/bonus")}>
          <GiftSkyla/>
            </IconBg>
           {
            !isMobile&&( <IconBg1 onClick={support}>
              <MessageIcon />
              <NotificationsSkyla/>
            </IconBg1>)
           }
            {/* <IconBg>
              <GlobeIcon />
            </IconBg> */}

            {
              !isMobile ?<ProfileMenu />:(
                <ProfileButton
                to="/mobile-user"
               
                >
                    <ProfileImage
                        src="//img2.distributedresourcestorage.com/avatar/57268122/s?t=0"
                        alt="Profile"
                    />
                </ProfileButton>
              )
            }
            

          
          </RightSection>
        </HeaderContainer>
      </HeaderWrapper>
      <BottomNavigation/>
    </div>
  );
};

export default Header;